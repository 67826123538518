var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-video" },
    [
      _vm.type == "video"
        ? _c("Xgplayer", {
            staticClass: "xgplayer-is-fullscreen",
            attrs: { id: "materialDetail", config: _vm.playerConfig },
            on: {
              player: function($event) {
                return _vm.playInit($event)
              }
            }
          })
        : _vm._e(),
      _vm.type == "audio"
        ? _c("div", { attrs: { id: "audioBox" } }, [
            _c("img", {
              staticStyle: {
                "max-height": "100%",
                width: "100%",
                "flex-shrink": "0"
              },
              attrs: {
                src: require("../assets/images/music_cover.gif"),
                alt: ""
              }
            }),
            _c("div", { attrs: { id: "audio_play" } })
          ])
        : _vm._e(),
      _vm.ifFullScreen
        ? _c(
            "div",
            {
              staticClass: "video-controls",
              on: { mousedown: _vm.handleClick }
            },
            [
              _c("div", { ref: "thumbs", staticClass: "thumbs" }),
              _c("div", { ref: "control-bars", staticClass: "control-bars" }, [
                _c("div", {
                  staticClass: "control-bars-mask left",
                  style: "width:" + _vm.leftMovePercentage + "%;",
                  attrs: { "data-direction": "left" }
                }),
                _c("div", {
                  staticClass: "control-bars-mask right",
                  style: "width:" + _vm.rightMovePercentage + "%;",
                  attrs: { "data-direction": "right" }
                }),
                _c("div", {
                  staticClass: "control-bars-progress",
                  class: { animate: _vm.videoEdit.play },
                  style: "left:" + _vm.videoEdit.currentPosition + "%;"
                }),
                _c(
                  "div",
                  {
                    staticClass: "control-bars-wrapper",
                    style:
                      "left:" +
                      _vm.leftMovePercentage +
                      "%;right:" +
                      _vm.rightMovePercentage +
                      "%;"
                  },
                  [
                    _c("div", {
                      ref: "move-left-icon",
                      staticClass: "control-bars-move left",
                      on: {
                        mousedown: function($event) {
                          return _vm.handleMoveDown($event, "left")
                        }
                      }
                    }),
                    _c("div", {
                      ref: "move-right-icon",
                      staticClass: "control-bars-move right",
                      on: {
                        mousedown: function($event) {
                          return _vm.handleMoveDown($event, "right")
                        }
                      }
                    })
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.ifFullScreen
        ? _c("div", { staticClass: "video-btn" }, [
            _c("div", { staticClass: "crop-range" }, [
              _c("div", { staticClass: "crop-input crop-start" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.inputStartLeftTime,
                      expression: "inputStartLeftTime",
                      modifiers: { number: true }
                    }
                  ],
                  attrs: {
                    type: "text",
                    placeholder: "00",
                    maxlength: "2",
                    id: "range-0",
                    disabled: ""
                  },
                  domProps: { value: _vm.inputStartLeftTime },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputStartLeftTime = _vm._n($event.target.value)
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _c("span", [_vm._v("：")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.inputStartRightTime,
                      expression: "inputStartRightTime",
                      modifiers: { number: true }
                    }
                  ],
                  staticStyle: { "text-align": "right" },
                  attrs: {
                    type: "text",
                    placeholder: "00",
                    maxlength: "2",
                    id: "range-1",
                    disabled: ""
                  },
                  domProps: { value: _vm.inputStartRightTime },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputStartRightTime = _vm._n($event.target.value)
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]),
              _c("span", { staticStyle: { color: "#fff", margin: "0 10px" } }, [
                _vm._v("一")
              ]),
              _c("div", { staticClass: "crop-input crop-end" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.inputEndLeftTime,
                      expression: "inputEndLeftTime",
                      modifiers: { number: true }
                    }
                  ],
                  attrs: {
                    type: "text",
                    placeholder: "00",
                    maxlength: "2",
                    id: "range-2",
                    disabled: ""
                  },
                  domProps: { value: _vm.inputEndLeftTime },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputEndLeftTime = _vm._n($event.target.value)
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _c("span", [_vm._v("：")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.inputEndRightTime,
                      expression: "inputEndRightTime",
                      modifiers: { number: true }
                    }
                  ],
                  staticStyle: { "text-align": "right" },
                  attrs: {
                    type: "text",
                    placeholder: "00",
                    maxlength: "2",
                    id: "range-3",
                    disabled: ""
                  },
                  domProps: { value: _vm.inputEndRightTime },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputEndRightTime = _vm._n($event.target.value)
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }