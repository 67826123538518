<template>
  <div v-if="hasLimits == 0" class="meterial_detail">
    <div>
      <!-- 视频详情 -->
      <div class="video_detail common_detail" v-if="materialType == 'video'">
        <el-row type="flex" class="material_content">
          <el-col :span="16" style="margin-right:45px">
            <div class="video_box stripe_box">
              <vue-video-clip
                :url="materialDetailData.url"
                :current="clipCurrent"
                @getTime="getTime"
                ref="VideoClip"
                @playInit="playInit"
                :type="materialType"
              ></vue-video-clip>
              <!-- <div style="margin-left:60px">
                <div>start：{{ current.start }}</div>
                <div>end：{{ current.end }}</div>
              </div> -->
              <div class="hover_effect">
                <span class="iconfont icon-fenxiang" @click="copyPath"></span>
                <span
                  class="iconfont icon-xiazai"
                  @click="videoDownloadConfirm"
                ></span>
                <span
                  @click="addWatchList()"
                  class="iconfont icon-tianjiazhiguankanliebiao"
                ></span>
                <span
                  class="iconfont icon-weishoucang"
                  @click="addCollet"
                  v-if="materialDetailData.collect_if == 0 ? true : false"
                ></span>
                <span
                  class="iconfont icon-shoucang"
                  @click="addCollet"
                  v-else
                ></span>
                <div
                  class="existed_tag"
                  style="top: -37px; left: -28%; letter-spacing: 2px"
                  v-if="ifcopy"
                >
                  已复制链接
                </div>
              </div>
            </div>
            <div>
            </div>
            <div class="material_bottom">
              <el-row type="flex" class="top_title">
                <el-col>
                  <div class="title_btn">
                    <el-button size="small" type="primary" class="stripe_btn" @click="addStripe" :loading="addStripeLoading">
                      加入拆条列表
                    </el-button>
                  </div>
                </el-col>
              </el-row>
              <span class="material_title">{{ materialDetailData.name }}</span>
              <p class="material_subcribe">{{ materialDetailData.introduce }}</p>
              <p class="material_info">编号：{{ materialDetailData.ip_id | editId }}</p>
              <p class="material_info">商用权限：{{materialDetailData.commercial_licenses}}</p>
              <p class="material_info"><span class="info_mr">分辨率：{{ materialDetailData.resolution }}</span><span class="info_mr">帧率：{{ materialDetailData.frame }}</span><span class="info_mr">大小：{{ materialDetailData.size | transformByte }}</span><span class="info_mr">类型：{{ materialDetailData.format }}</span><span class="info_mr">编码：{{ materialDetailData.coding }}</span><span class="info_mr">时长：{{ materialDetailData.duration }}</span></p>
              <p class="material_info">作者/来源：{{ materialDetailData.uploader }}</p>
              <p class="material_info">创建时间：{{ materialDetailData.upload_time | formatTime }} </p>
            </div>
          </el-col>
          <el-col :span="8">
            <el-tabs v-model="activeName" @tab-click="handleClick" class="video_tabs">
              <el-tab-pane label="标签" name="first">
                 <div class="ai_intelligence">
                  <div
                    class="row_line"
                    v-for="(item, index) in labelGroupData"
                    :key="index"
                    v-show="!(item.labelData.length==0)"
                  >
                    <span class="category">{{ item.labelGroupName }}</span>
                    <div class="ai_tag click_tag">
                      <el-tag
                        :key="tag.tag_id"
                        v-for="tag in item.labelData"
                        :disable-transitions="false"
                        @close="handleClose(tag.tag_id,1)"
                        @click="tagClick(tag)"
                        :class="tag.select?'select_tag':''"
                      >
                        {{ tag.tag_name }}
                      </el-tag>
                    </div>
                  </div>
                  <div class="row_line" style="align-items:center;">
                    <span class="category" v-if="showKeywords" style="height:30px;">关键词</span>
                    <div class="ai_tag">
                      <el-tag
                        :key="tag.id"
                        class="key-tag"
                        v-for="tag in keyWordsTags"
                        :closable="allowEdit ? true : false"
                        :disable-transitions="false"
                        @close="handleClose(tag.id, 2)"
                      >
                        {{ tag.keyword }}
                      </el-tag>
                      <!-- 关键词添加（加号） -->
                      <div v-if="allowEdit == 1 " style=" display: flex">
                        <el-input
                          v-focus
                          class="input-new-tag"
                          v-if="keyWordsVisible"
                          v-model="keyWordsValue"
                          ref="saveTagInput"
                          size="small"
                          @keyup.enter.native="handleInputConfirm()"
                        >
                        </el-input>
                        <div class="operation" v-if="keyWordsVisible">
                          <i
                            class="iconfont icon-duihao"
                            @click="handleInputConfirm()"
                          ></i>
                          <i class="iconfont icon-cuo" @click="clearInput"></i>
                        </div>
                          <el-button
                            class="button-new-tag"
                            size="small"
                            type="primary"
                            @click="addNewLabel"
                            v-else
                          >
                            <i class="iconfont icon-jiahao1"></i>
                          </el-button>
                        <div
                          class="existed_tag category_tag"
                          v-if="keyWordsExistedTag"
                        >
                          {{ tip }}
                        </div>
                      </div>
                      <!-- 关键词添加 -->
                    </div>
                  </div>
                  <div class="no_data" v-if="!showKeywords && labelGroupData.length == 0">
                    暂无相关标签和关键词~
                  </div>
              </div>
              </el-tab-pane>
              <div class="ocr_search" v-if="activeName == 'second'">
                <el-input
                  placeholder="请输入关键字"
                  @keyup.enter.native="onAudioSearch"
                  v-model="AudioSearch">
                  <el-button slot="append" icon="el-icon-search" @click="onAudioSearch"></el-button>
                </el-input>
              </div>
              <el-tab-pane label="语音" name="second" class="second_content" v-infinite-scroll="loadMoreAudio" :infinite-scroll-distance="10">
                <div class="ocr_text" v-for="(item,index) in audioList" :key="index" :id="'audioTime'+ Object.values(item)[1]">
                  <p :class="['time',item.highLight?'ocr_text_select':'']" @click="goCurrentTime(item)">{{Object.keys(item)[0]}}</p>
                  <p class="text" v-html="Object.values(item)[0]"></p>
                </div>
                <p v-if="loadingAudio" style="text-align:center;color:rgba(255,255,255,0.6);">加载中...</p>
                <p v-if="noMoreAudio && audioList.length" style="text-align:center;color:rgba(255,255,255,0.6);">没有更多了~</p>
                <div class="no_data" v-if="!audioList.length && !loadingAudio">
                  暂无相关语音~
                </div>
              </el-tab-pane>
              <div class="ocr_search" v-if="activeName == 'third'">
                <el-input
                  placeholder="请输入关键字"
                  @keyup.enter.native="onOcrSearch"
                  v-model="ocrSearch">
                  <el-button slot="append" icon="el-icon-search" @click="onOcrSearch"></el-button>
                </el-input>
              </div>
              <el-tab-pane label="文本" name="third" class="third_content" v-infinite-scroll="loadMoreOcr" :infinite-scroll-distance="10">
                <div class="ocr_text" v-for="(item,index) in ocrList" :key="index" :id="'ocrTime'+ Object.values(item)[1]">
                  <p :class="['time',item.highLight?'ocr_text_select':'']" @click="goCurrentTime(item)">{{Object.keys(item)[0]}}</p>
                  <p class="text" v-html="Object.values(item)[0]"></p>
                </div>
                <p v-if="loadingOcr" style="text-align:center;color:rgba(255,255,255,0.6);">加载中...</p>
                <p v-if="noMoreOcr && ocrList.length" style="text-align:center;color:rgba(255,255,255,0.6);">没有更多了~</p>
                <div class="no_data" v-if="!ocrList.length && !loadingOcr">
                  暂无相关文本~
                </div>
              </el-tab-pane>
              <el-tab-pane label="关联音乐" name="fourth">
                <div class="related_material related_audio" v-for="(item,i) in relatedData" :key="i">
                  <div class="left_img">
                    <img src="../assets/images/headset.png" alt="">
                    <p class="video_time">{{item.duration}}</p>
                  </div>
                  <div class="right_info">
                    <el-tooltip v-delTabIndex effect="dark" :content="item.name" placement="bottom-start" popper-class="audio_tooltip">
                      <span class="one_line_ellipsis title" @click="goMaterialDetail(item.ip_id,'audio')">{{item.name}}</span>
                    </el-tooltip>
                    <p class="audio_mt">{{item.photographer}}</p>
                    <span class="related">关联视频数量：{{item.related_video_num}}</span>
                  </div>
                </div>
                <div class="no_data" v-if="!relatedData.length">
                  暂时没有相关素材哦~
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </div>

      <!-- 图片详情 -->
      <div v-if="materialType == 'img'" class="img_detail common_detail">
        <el-row type="flex" class="material_content">
          <el-col :span="16" style="margin-right:45px">
            <div class="video_box">
              <img
                :src="materialDetailData.url"
                alt=""
                style="max-width: 100%; max-height: 100%;flex-shrink: 0;"
              />
              <div class="hover_effect">
                <span class="iconfont icon-fenxiang" @click="copyPath"></span>
                <span
                  class="iconfont icon-xiazai"
                  @click="videoDownloadConfirm"
                ></span>
                <span
                  class="iconfont icon-weishoucang"
                  @click="addCollet"
                  v-if="materialDetailData.collect_if == 0 ? true : false"
                ></span>
                <span
                  class="iconfont icon-shoucang"
                  @click="addCollet"
                  v-else
                ></span>
                <div
                  class="existed_tag"
                  style="top: -37px; left: -28%; letter-spacing: 2px"
                  v-if="ifcopy"
                >
                  已复制链接
                </div>
              </div>
            </div>
            <div class="material_bottom">
              <el-row type="flex" class="top_title">
                <el-col>
                  <div class="title_btn">
                    <el-button size="small" type="primary" class="stripe_btn" @click="addStripe" :loading="addStripeLoading">
                      加入拆条列表
                    </el-button>
                  </div>
                </el-col>
              </el-row>
              <span class="material_title">{{ materialDetailData.name }}</span>
              <p class="material_subcribe">{{ materialDetailData.introduce }}</p>
              <p class="material_info">编号：{{ materialDetailData.ip_id | editId }}</p>
              <p class="material_info">商用权限：{{materialDetailData.commercial_licenses}}</p>
              <p class="material_info"><span class="info_mr">分辨率：{{ materialDetailData.resolution }}</span><span class="info_mr">大小：{{ materialDetailData.size | transformByte }}</span><span class="info_mr">类型：{{ materialDetailData.format }}</span></p>
              <p class="material_info">作者/来源：{{ materialDetailData.uploader }}</p>
              <p class="material_info">创建时间：{{ materialDetailData.upload_time | formatTime }} </p>
            </div>
          </el-col>
          <el-col :span="8">
            <el-tabs v-model="activeName" @tab-click="handleClick" class="img_tabs">
              <el-tab-pane label="标签" name="first">
                 <div class="ai_intelligence">
                  <div
                    class="row_line"
                    v-for="(item, index) in labelGroupData"
                    :key="index"
                    v-show="!(item.labelData.length==0)"
                  >
                    <span class="category">{{ item.labelGroupName }}</span>
                    <div class="ai_tag">
                      <el-tag
                        :key="tag.tag_id"
                        v-for="tag in item.labelData"
                        :disable-transitions="false"
                        @close="handleClose(tag.tag_id,1)"
                      >
                        {{ tag.tag_name }}
                      </el-tag>
                    </div>
                  </div>
                  <div class="row_line" style="align-items:center;">
                    <span class="category" v-if="showKeywords" style="height:30px;">关键词</span>
                    <div class="ai_tag">
                      <el-tag
                        :key="tag.id"
                        class="key-tag"
                        v-for="tag in keyWordsTags"
                        :closable="allowEdit ? true : false"
                        :disable-transitions="false"
                        @close="handleClose(tag.id, 2)"
                      >
                        {{ tag.keyword }}
                      </el-tag>
                      <!-- 关键词添加（加号） -->
                      <div v-if="allowEdit == 1 " style=" display: flex">
                        <el-input
                          v-focus
                          class="input-new-tag"
                          v-if="keyWordsVisible"
                          v-model="keyWordsValue"
                          ref="saveTagInput"
                          size="small"
                          @keyup.enter.native="handleInputConfirm()"
                        >
                        </el-input>
                        <div class="operation" v-if="keyWordsVisible">
                          <i
                            class="iconfont icon-duihao"
                            @click="handleInputConfirm()"
                          ></i>
                          <i class="iconfont icon-cuo" @click="clearInput"></i>
                        </div>
                          <el-button
                            class="button-new-tag"
                            size="small"
                            type="primary"
                            @click="addNewLabel"
                            v-else
                          >
                            <i class="iconfont icon-jiahao1"></i>
                          </el-button>
                        <div
                          class="existed_tag category_tag"
                          v-if="keyWordsExistedTag"
                        >
                          {{ tip }}
                        </div>
                      </div>
                      <!-- 关键词添加 -->

                    </div>
                  </div>
                  <div class="no_data" v-if="!showKeywords && labelGroupData.length == 0">
                    暂无相关标签和关键词~
                  </div>
                </div>
              </el-tab-pane>
              <div class="ocr_search" v-if="activeName == 'second'">
                <el-input
                  placeholder="请输入关键字"
                  @keyup.enter.native="onOcrSearch"
                  v-model="ocrSearch">
                  <el-button slot="append" icon="el-icon-search" @click="onOcrSearch"></el-button>
                </el-input>
              </div>
              <el-tab-pane label="文本" name="second" class="third_content" v-infinite-scroll="loadMoreOcr" :infinite-scroll-distance="10">
                <div class="ocr_text">
                  <p class="time" v-html="ocrList[0]"></p>
                </div>
                <p v-if="loadingOcr" style="text-align:center;color:rgba(255,255,255,0.6);">加载中...</p>
                <p v-if="noMoreOcr && ocrList[0] != ''" style="text-align:center;color:rgba(255,255,255,0.6);">没有更多了~</p>
                <div class="no_data" v-if="ocrList[0] == '' && !loadingOcr">
                  暂无相关文本~
                </div>
              </el-tab-pane>
            </el-tabs>

          </el-col>
        </el-row>
      </div>

      <!-- 音频详情 -->
      <div v-if="materialType == 'audio'" class="audio_detail common_detail">
        <el-row type="flex" class="material_content">
          <el-col :span="16" style="margin-right:45px;">
            <div class="video_box stripe_box">
              <!-- <img
                src="../assets/images/music_cover.gif"
                alt=""
                style="max-height: 100%;width:100%;flex-shrink: 0;"
              /> -->
              <div class="hover_effect">
                <span class="iconfont icon-fenxiang" @click="copyPath"></span>
                <span
                  class="iconfont icon-xiazai"
                  @click="videoDownloadConfirm"
                ></span>
                <span
                  class="iconfont icon-weishoucang"
                  @click="addCollet"
                  v-if="materialDetailData.collect_if == 0 ? true : false"
                ></span>
                <span
                  class="iconfont icon-shoucang"
                  @click="addCollet"
                  v-else
                ></span>
                <div
                  class="existed_tag"
                  style="top: -37px; left: -28%; letter-spacing: 2px"
                  v-if="ifcopy"
                >
                  已复制链接
                </div>
              </div>
              <!-- <div id="audio_play"></div> -->
               <vue-video-clip
                :url="materialDetailData"
                @getTime="getTime"
                :current="clipCurrent"
                ref="VideoClip"
                @setAudio="setAudio"
                :type="materialType"
              ></vue-video-clip>
               <!-- <div style="margin-left:60px">
                <div>start：{{ current.start }}</div>
                <div>end：{{ current.end }}</div>
              </div> -->
            </div>
            <div class="material_bottom">
              <el-row type="flex" class="top_title">
                <el-col>
                  <div class="title_btn">
                    <el-button size="small" type="primary" class="stripe_btn" @click="addStripe" :loading="addStripeLoading">
                      加入拆条列表
                    </el-button>
                  </div>
                </el-col>
              </el-row>
              <span class="material_title">{{ materialDetailData.name }}</span>
              <p class="material_subcribe">{{ materialDetailData.introduce }}</p>
              <p class="material_info">编号：{{ materialDetailData.ip_id | editId }}</p>
              <p class="material_info">商用权限：{{materialDetailData.commercial_licenses}}</p>
              <p class="material_info"><span class="info_mr">大小：{{ materialDetailData.size | transformByte }}</span><span class="info_mr">类型：{{ materialDetailData.format }}</span><span class="info_mr">时长：{{ materialDetailData.duration }}</span></p>
              <p class="material_info">作者/来源：{{ materialDetailData.uploader }}</p>
              <p class="material_info">创建时间：{{ materialDetailData.upload_time | formatTime }} </p>
            </div>
          </el-col>
          <el-col :span="8">
            <el-tabs v-model="activeName" @tab-click="handleClick" class="audio_tabs">
              <!-- 暂时隐藏音频的标签 -->
              <!-- <el-tab-pane label="标签" name="first">
                 <div class="ai_intelligence">
                  <div
                    class="row_line"
                    v-for="(item, index) in labelGroupData"
                    :key="index"
                    v-show="!(item.labelData.length==0)"
                  >
                    <span class="category">{{ item.labelGroupName }}</span>
                    <div class="ai_tag">
                      <el-tag
                        :key="tag.tag_id"
                        v-for="tag in item.labelData"
                        :disable-transitions="false"
                        @close="handleClose(tag.tag_id,1)"
                      >
                        {{ tag.tag_name }}
                      </el-tag>
                    </div>
                  </div>
                  <div class="row_line" style="align-items:center;">
                    <span class="category" v-if="showKeywords" style="height:30px;">关键词</span>
                    <div class="ai_tag">
                      <el-tag
                        :key="tag.id"
                        class="key-tag"
                        v-for="tag in keyWordsTags"
                        :closable="allowEdit ? true : false"
                        :disable-transitions="false"
                        @close="handleClose(tag.id, 2)"
                      >
                        {{ tag.keyword }}
                      </el-tag>

                      <div v-if="allowEdit == 1 " style=" display: flex">
                        <el-input
                          v-focus
                          class="input-new-tag"
                          v-if="keyWordsVisible"
                          v-model="keyWordsValue"
                          ref="saveTagInput"
                          size="small"
                          @keyup.enter.native="handleInputConfirm()"
                        >
                        </el-input>
                        <div class="operation" v-if="keyWordsVisible">
                          <i
                            class="iconfont icon-duihao"
                            @click="handleInputConfirm()"
                          ></i>
                          <i class="iconfont icon-cuo" @click="clearInput"></i>
                        </div>
                          <el-button
                            class="button-new-tag"
                            size="small"
                            type="primary"
                            @click="addNewLabel"
                            v-else
                          >
                            <i class="iconfont icon-jiahao1"></i>
                          </el-button>
                        <div
                          class="existed_tag category_tag"
                          v-if="keyWordsExistedTag"
                        >
                          {{ tip }}
                        </div>
                      </div>


                    </div>
                  </div>
                  <div class="no_data" v-if="!showKeywords && labelGroupData.length == 0">
                    暂无相关标签和关键词~
                  </div>
              </div>
              </el-tab-pane> -->
              <div class="ocr_search" v-if="activeName == 'first'">
                <el-input
                  placeholder="请输入关键字"
                  @keyup.enter.native="onAudioSearch"
                  v-model="AudioSearch">
                  <el-button slot="append" icon="el-icon-search" @click="onAudioSearch"></el-button>
                </el-input>
              </div>
              <el-tab-pane label="语音" name="first" class="second_content" v-infinite-scroll="loadMoreAudio" :infinite-scroll-distance="10">
                <div class="ocr_text" v-for="(item,index) in audioList" :key="index" :id="'audioTime1'+ Object.values(item)[1]">
                  <p :class="['time',item.highLight?'ocr_text_select':'']" @click="goCurrentTime(item)">{{Object.keys(item)[0]}}</p>
                  <p class="text" v-html="Object.values(item)[0]"></p>
                </div>
                <p v-if="loadingAudio" style="text-align:center;color:rgba(255,255,255,0.6);">加载中...</p>
                <p v-if="noMoreAudio && audioList.length" style="text-align:center;color:rgba(255,255,255,0.6);">没有更多了~</p>
                <div class="no_data" v-if="!audioList.length && !loadingAudio">
                  暂无相关语音~
                </div>
              </el-tab-pane>
              <el-tab-pane label="关联视频" name="third">
                <div v-for="(item, key, index) of relatedData" :key="index">
                  <span style="color:rgba(255, 255, 255, 0.6);font-size:12px;">{{key}}</span>
                  <div class="related_material related_video" v-for="(item1,i) in item" :key="i">
                    <div class="left_img">
                      <img :src="item1.img_url" alt="" @mouseenter.stop.self="imgMouseenter(item1)" @mouseleave.stop.self="imgMouseleave(item1)">
                      <p class="video_time">{{item1.duration}}</p>
                    </div>
                    <div class="right_info">
                      <el-tooltip v-delTabIndex effect="dark" :content="item1.name" placement="bottom-start" popper-class="audio_tooltip">
                        <span class="one_line_ellipsis title" @click="goMaterialDetail(item1.ip_id,'video')">{{item1.name}}</span>
                      </el-tooltip>
                      <el-tooltip v-delTabIndex effect="dark" :content="item1.introduce" placement="bottom-start" popper-class="audio_tooltip">
                        <span class="audio_mt one_line_ellipsis" v-if="item1.introduce">{{ '简介：'+ item1.introduce}}</span>
                      </el-tooltip>
                        <span class="related">关联音频数量：{{item1.related_audio_num}}</span>
                    </div>
                  </div>
                </div>
                <div class="no_data" v-if="!Object.keys(relatedData).length">
                  暂时没有相关素材哦~
                </div>
              </el-tab-pane>
            </el-tabs>

          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 对话框 -->
    <el-dialog
      title="下载"
      :visible.sync="downloadDialog"
      width="461px"
      top="30vh"
      custom-class="material-dialog"
      :show-close="true"
    >
      <span style="display: block; margin-bottom: 21px">分辨率：</span>
      <div class="radio_check">
        <el-radio label="标清" v-if="resolutions.length == 1" class="disable_radio">标清</el-radio>
        <el-radio
          v-for="(item, index) in resolutions"
          :key="index"
          v-model="system"
          :label="item.url"
          >{{ item.resolution }}</el-radio
        >
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadConfirm">确 定</el-button>
        <el-button @click="downloadDialog = false" style="margin-right: 30px"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
  <div v-else-if="hasLimits == 1"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    "
  >
    <div style="text-align:center" >
      <i
        style="font-size: 100px; color: rgb(94, 95, 114)"
        class="iconfont icon-meiyouquanxian"
      ></i>
     <div style=" font-weight: bold;font-family: Microsoft YaHei;font-size: 20px; color: rgb(94, 95, 114)" > 您暂时没有浏览权限</div>
    </div>
  </div>
</template>

<script>
import Xgplayer from "xgplayer-vue";
// import Music from 'xgplayer-music';

// import "../assets/xgplayer-music/music";
// import '../assets/.xgplayer/skin/index.js'

import $ from "jquery";
import vueVideoClip from '../component/vueVideoClip.vue';
export default {
  name: "materialDetail",
  components: {
    Xgplayer,
    vueVideoClip
  },
  data() {
    return {
      materialDetailData: "", //视频图片的数据
      player: null,
      musicPlayer: null,
      ifcopy: false, //是否复制
      downloadDialog: false, //显示隐藏下载对话框
      labelGroupData: [], //标签组数据
      keyWordsTags: [], //关键词 -数组
      system: "", //选择的分辨率
      resolutions: [], //下载的分辨率
      keyWordsVisible: false, //加号跟输入框的切换显示
      keyWordsValue: "", // 输入框值
      keyWordsExistedTag: false, //显示隐藏关键词已经存在
      tip: "", //提示词
      hasLimits: 0, //控制有无权限查看的隐藏显示
      allowEdit:'',//是否有权限可以修改数据
      activeName: 'first',

      ocrSearch: '', //ocr语音搜索
      AudioSearch: '', //语音搜索

      ipId: this.$route.query.ip_id,
      materialType: this.$route.query.materialType,

      ocrList: [],
      loadingOcr: false,
      noMoreOcr: false,
      busyScrollOcr: false,

      ocrPageSize: 50, //每页显示
      ocrPageNum: 0, //当前页
      ocrPageTotal: 0, //总条数

      audioList: [],
      loadingAudio: false,
      noMoreAudio: false,
      busyScrollAudio: false,

      audioPageSize: 50, //每页显示
      audioPageNum: 0, //当前页
      audioPageTotal: 0, //总条数

      relatedData: '', //相关视频、音频，当是相关视频是对象，相关音频是数组
      current: {},  //由视频拆条组件传到详情页面的时间
      clipCurrent: { //由左侧拆条列表的每一项跳转到详情，需要带入拆过的时间
        start: null,
        end: null
      },
      addStripeLoading: false //加入拆条列表，再返回结果之前的loading效果
    };
  },
  computed: {
    paramsType() {
      switch (this.materialType){
        case 'video':
          return 1
        case 'img':
          return 2
        case 'audio':
          return 3
      }
    },
    showKeywords() {
      if(this.allowEdit){
        return true
      }else{
        if(this.keyWordsTags.length){
          return true
        }else{
          return false
        }
      }
    },
  },
  mounted() {
    let video_boxW = $(".video_box").width();
    $(".video_box, #materialDetail, #audioBox").css("height", video_boxW * 0.587 + "px");
    window.onresize = () => {
      let video_boxW = $(".video_box").width();
      $(".video_box, #materialDetail, #audioBox").css("height", video_boxW * 0.587 + "px");
    };
    this.getLabelData();
  },
  methods: {
    setAudio(e) {
      this.musicPlayer = e
      this.musicPlayer.on("timeupdate", () => {
        this.audioList.map((obj)=>{
          obj.highLight = false
         if(Object.values(obj)[1] == this.formatDecimal(this.musicPlayer.currentTime,0) ){
           obj.highLight = true
           this.goAnchor("#audioTime1" + Object.values(obj)[1])
           this.$forceUpdate()
         }
        })
      });
      this.musicPlayer.on("ended",() => {
        this.musicPlayer.currentTime = this.current.start,0
        setTimeout(()=>{
          this.musicPlayer.pause()
        },50)
      })
    },
    handleClick(tab, event) {
        // console.log(tab, event);
    },
    playInit(e) {
       this.player = e;
       this.monitorPlaying();
      // this.player.emit('resourceReady', [{ name: '超清', url: '//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/mp4/xgplayer-demo-720p.mp4' }, { name: '高清', url: '//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/mp4/xgplayer-demo-480p.mp4' }, { name: '标清', url: '//sf1-cdn-tos.huoshanstatic.com/obj/media-fe/xgplayer_doc_video/mp4/xgplayer-demo-360p.mp4' }])
    },

    // 监听视频播放状态
    monitorPlaying() {
      this.player.on("playing", () => {
        // 通知小窗播放视频暂停
        this.$store.commit('setplaying',2)
       setTimeout(() => {
          this.$store.commit('setplaying',0)
       }, 0);
      });
      this.player.on("timeupdate", () => {
        this.activeName =='third' && this.ocrList.length && this.ocrList.map((obj)=>{
          obj.highLight = false
         if(Object.values(obj)[1] == this.formatDecimal(this.player.currentTime,0) ){
           obj.highLight = true
           this.goAnchor("#ocrTime" + Object.values(obj)[1])
           this.$forceUpdate()
         }
        })
       this.activeName =='second' && this.audioList.length && this.audioList.map((obj)=>{
          obj.highLight = false
         if(Object.values(obj)[1] == this.formatDecimal(this.player.currentTime,0) ){
           obj.highLight = true
           this.goAnchor("#audioTime" + Object.values(obj)[1])
           this.$forceUpdate()
         }
        })
      });
    },


    // 分享链接按钮
    copyPath() {
      this.ifcopy = true;
      this.copyUrl();
      setTimeout(() => {
        this.ifcopy = false;
      }, 3000);
    },

    // 添加/取消收藏
    addCollet() {
      let formData = new FormData();
      formData.append("ip_id", this.materialDetailData.ip_id);
      formData.append("type", this.paramsType);
      this.$http({
        method: "POST",
        url: "vw_material/add_my_collect",
        data: formData,
      }).then((res) => {
        if (res.data.msg == "收藏成功") {
          this.materialDetailData.collect_if = 2;
        }
        if (res.data.msg == "取消收藏成功") {
          this.materialDetailData.collect_if = 0;
        }
      }).catch(error => console.log(error));
    },
    // 下载按钮
    videoDownloadConfirm() {

      // 如果是图片或音频
      if (this.materialType == "img" || this.materialType == "audio") {
        this.downloadImg(this.materialDetailData.url, this.materialDetailData.name);
      }

      // 如果是视频
      if (this.materialType == "video") {
        let formData = new FormData();
        formData.append("ip_id", this.materialDetailData.ip_id);
        this.$http({
          url: "/upload/get_download_res",
          method: "POST",
          data: formData,
        }).then((res) => {
          if (res.data.status == 0) {
            this.resolutions = res.data.result;
            this.system = res.data.result[0].url;
            // 弹出下载框
            this.downloadDialog = true;
          }
        }).catch(error => console.log(error));
      }
    },

    // 下载框确认按钮
    downloadConfirm() {
     // 切换视频配置源 然后视频下载。
      let link = document.createElement('a');
      link.href = this.system  //链接
      document.body.appendChild(link) //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link) // 下载完成移除元素
      this.downloadDialog = false;
    },

    // 下载图片 传入下载地址
    downloadImg(imgsrc, name) {
        let download_name = ''
      let link = document.createElement('a');
      link.download = download_name  // 下载文件的名字
      // console.log(download_name)
      link.href = imgsrc  //链接
      document.body.appendChild(link) //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link) // 下载完成移除元素
    },

    // 加入观看列表事件
    addWatchList(id) {
      let formData = new FormData();
      formData.append("ip_id", this.materialDetailData.ip_id);
      this.$http({
        method: "POST",
        url: "vw_material/add_my_watch_record",
        data: formData,
      }).then((res) => {
        // 加入成功
        if (res.data.status == 0) {
          this.$store.commit("editAdd", true);
          setTimeout(() => {
            this.$store.commit("editAdd", false);
          }, 1000);
        }
      }).catch(error => console.log(error));
    },

    //删除关键词
    handleClose(del_id, del_type) {
      let params = JSON.stringify({
        ip_id: this.ipId + "",
        del_type: del_type + "",
        del_id: del_id + "",
      });
      // console.log(params)
      this.$http({
        url: "vw_material/update_ip_tag_and_keyword",
        method: "DELETE",
        data: params,
      }).then((res) => {
        if(res.data.status==0){
          // console.log(res.data)
             this.getLabelData();
        }
      }).catch(error => console.log(error));
    },

    // 错号按钮
    clearInput() {
      this.keyWordsValue = "";
      this.keyWordsVisible = false;
      this.keyWordsExistedTag = false;
    },

    // 对号按钮
    handleInputConfirm() {
      let str = this.keyWordsValue.trim();
      // 如果是空格，
      if (str.length == 0) {
        this.keyWordsExistedTag = true;
        this.tip = "输入为空";
        setTimeout(() => {
          this.keyWordsExistedTag = false;
        }, 3000);
      }
      //不允许首尾有空格
      else {
        let params = JSON.stringify({
          ip_id: this.ipId + "",
          add_type: '2',
          add_name: str + "",
        });
        this.$http({
          url: "vw_material/update_ip_tag_and_keyword",
          method: "POST",
          data: params,
        }).then((res) => {
          // console.log(res.data);
          // 添加成功重新获取标签跟关键词数据
          if (res.data.status == 0) {
            this.keyWordsValue=''
            this.getLabelData();
          }
          // 关键词已经存在
          else {
            this.getLabelData();
            this.keyWordsExistedTag = true;
            this.tip = res.data.msg;
            setTimeout(() => {
              this.keyWordsExistedTag = false;
            }, 3000);
          }
        }).catch(error => console.log(error));
      }
    },

    showInput(num) {
      switch (num) {
        case 0:
          this.sceneVisible = true;
          this.$nextTick((_) => {
            this.$refs.saveTagInput.$refs.input.focus();
          });
          break;
        case 1:
          this.characterVisible = true;
          this.$nextTick((_) => {
            this.$refs.saveTagInput.$refs.input.focus();
          });
          break;
        case 2:
          this.objectVisible = true;
          this.$nextTick((_) => {
            this.$refs.saveTagInput.$refs.input.focus();
          });
          break;
        case 3:
          this.keyWordsVisible = true;
          this.$nextTick((_) => {
            this.$refs.saveTagInput.$refs.input.focus();
          });
          break;
        default:
          break;
      }
    },

    // 复制链接事件
    copyUrl() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = encodeURI(this.materialDetailData.copy_url); // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    },

    addNewLabel() {
      // 显示对话框
      this.keyWordsVisible = true;
    },

    // 请求素材详情
    getDetail() {
      this.$http({
        url: "vw_material/get_ip_info",
        method: "GET",
        params: { ip_id: this.ipId },
      }).then((res) => {
        // 无权限查看
        if (res.data.status == 1) {
           this.hasLimits = 1;
        }
        // // 有权限查看
        if (res.data.status == 0) {
           this.hasLimits = 0;
           this.materialDetailData = res.data.result;
           this.allowEdit = res.data.result.tag_allow_change_if

           this.materialType == 'audio' && this.$nextTick(()=>{
            // setTimeout(()=>{
              // this.setAudio(res.data.result.url,res.data.result.short_video,res.data.result.name)
            // },1000)
          })

        }
      }).catch(error => console.log(error));
    },

    // 请求标签组数据跟关键词数据
    getLabelData() {
      this.$http({
        url: "vw_material/update_ip_tag_and_keyword",
        method: "GET",
        params: { ip_id: this.ipId },
      }).then((res) => {
        // 关键词

        this.keyWordsTags = res.data.keyword_list;
        // 将标签组数据进行修改
        this.editLabelData(res.data.tag_group_dict);
      }).catch(error => console.log(error));
    },

    // 将标签组数据进行修改
    editLabelData(labelData) {
      let newarr = [];
      for (let key in labelData) {
        let newitem = {};
        newitem.labelGroupName = key;
        newitem.labelData = labelData[key];
        if(labelData.length != 1 && newitem.labelData.length != 0){
          newarr.push(newitem);
        }
      }
      this.labelGroupData = newarr;
    },

    // 搜索、获取ocr文本信息
    getOcrText() {
      this.$http({
        url: "vw_material/get_ocr_text",
        method: "GET",
        params: {
          ip_id: this.ipId,
          // ip_id: 2856,
          ip_type: this.paramsType,
          page: this.ocrPageNum,
          page_num: this.ocrPageSize,
          search_name: this.ocrSearch.trim()
        },
      }).then((res) => {
        this.ocrPageTotal = res.data.total_num
        this.loadingOcr = false
          this.ocrList = this.ocrList.concat(res.data.ocr_list);
        if(res.data.ocr_list.length == 0){
          this.busyScrollOcr = true
          this.noMoreOcr  = true
        }
        else {
            this.busyScrollOcr = false
        }
      }).catch(error => console.log(error));
    },
    onOcrSearch() {
      this.ocrPageNum = 1
      this.noMoreOcr = false
      this.loadingOcr = false
      this.ocrList = []
      this.getOcrText()
    },
    // ocr文本信息触底分页效果
    loadMoreOcr(){
      this.loadingOcr = true
      if(!this.busyScrollOcr){
        this.noMoreOcr = false
        setTimeout(() => {
            this.ocrPageNum += 1
            this.getOcrText()
          }, 2000)
      }else{
        this.noMoreOcr = true
        this.loadingOcr = false
      }
    },

    // 搜索、获取语音文本信息
    getAudioText() {
      this.$http({
        url: "vw_material/get_audio_text",
        method: "GET",
        params: {
          ip_id: this.ipId,
          // ip_id: 2989,
          page: this.audioPageNum,
          page_num: this.audioPageSize,
          search_name: this.AudioSearch.trim()
        },
      }).then((res) => {
        this.ocrPageTotal = res.data.total_num
        this.loadingAudio = false
        this.audioList = this.audioList.concat(res.data.ocr_list);
        if(res.data.ocr_list.length == 0){
          this.busyScrollAudio = true
          this.noMoreAudio  = true
        }
        else {
            this.busyScrollAudio = false
        }
      }).catch(error => console.log(error));
    },
    onAudioSearch() {
      this.audioPageNum = 1
      this.noMoreAudio = false
      this.loadingAudio = false
      this.audioList = []
      this.getAudioText()
    },
    // ocr文本信息触底分页效果
    loadMoreAudio(){
      this.loadingAudio = true
      if(!this.busyScrollAudio){
        this.noMoreAudio = false
        setTimeout(() => {
            this.audioPageNum += 1
            this.getAudioText()
          }, 2000)
      }else{
        this.noMoreAudio = true
        this.loadingAudio = false
      }
    },

    // 获取关联视频、音频
    getRelatedMaterial () {
      this.$http({
        url: "vw_material/get_related_video",
        method: "GET",
        params: {
          ip_id: this.ipId,
          // ip_id: 3146,
          // ip_id: 2224,
          ip_type: this.paramsType
        },
      }).then((res) => {
        this.relatedData = res.data.result
        if(this.paramsType == 3 && Object.keys(res.data.result).length) {  //音频-关联视频
          Object.values(res.data.result).forEach((item,i)=>{
             item.forEach((item1,j)=>{
               item1.img_url = item1.cover_img
             })
           })
        }
      }).catch(error => console.log(error));
    },
    imgMouseenter (item) {
      item.img_url = item.cover
      this.$forceUpdate()
    },

    imgMouseleave (item) {
      item.img_url = item.cover_img
      this.$forceUpdate()
    },

    goMaterialDetail (id,type) {
      const { href } = this.$router.resolve({
        name: "materialDetail",
        query: { ip_id: id, materialType: type },
      });
      window.open(href, "_blank");
    },

    goCurrentTime (item) {
      if(this.paramsType == 1) {
        this.ocrList.map((obj)=>{
          obj.highLight = false
        })
      }
      this.audioList.map((obj)=>{
        obj.highLight = false
      })
      item.highLight = true
      this.$forceUpdate()
      this.paramsType == 3 ? this.musicPlayer.currentTime = Object.values(item)[1] : this.player.currentTime = Object.values(item)[1]
    },

    // 标签点击在视频上显示对应段
    tagClick (item) {
      console.log(item)
      this.labelGroupData.forEach((item,i)=>{
        item.labelData.forEach((obj,j)=>{
          obj.select = false
        })
      })
      this.player.removeAllProgressDot() //删除所有标记

      item.select = true
      this.$forceUpdate()
      item.timestamp.length && item.timestamp.forEach((arr,i)=>{
        // time://展示标记起始时间start 、text://鼠标hover在标记时展示的文字、 duration: //标记段长度（以时长计算）、 style: //标记样式
        this.player.addProgressDot(arr[0], null, arr[1], { background: '#FDA600'}) //添加标记（参数含义同上，后3项可选传入）
        this.player.currentTime = item.timestamp[0][0]
        this.player.play()
        setTimeout(()=>{
          this.player.pause()
          this.player.currentTime = item.timestamp[0][0]
        },200)
      })
    },
    // 小数点保留
    formatDecimal(num, decimal) {
        num = num.toString()
        let index = num.indexOf('.')
        if (index !== -1) {
            num = num.substring(0, decimal + index + 1)
        } else {
            num = num.substring(0)
        }
        return parseFloat(num).toFixed(decimal)
    },

    // 滚动
    goAnchor(selector) {
      // console.log(selector)
      // console.log(this.$el)
      this.$el.querySelector(selector).scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block:    "center"  // 上边框与视窗顶部平齐。默认值
      });
    },

    // 拆条列表：获取当前时间
    getTime (val) {
      this.current = val
    },

    // 加入拆条列表点击事件
    addStripe () {
      // 处理上传数据
      let params = {
        ip_id: this.ipId,
        ip_type: this.paramsType
      }
      let notAllow = parseInt(this.current.end - this.current.start) < 1
        if(this.paramsType == 1 || this.paramsType == 3) { //视频、音频
          params.start_time = this.current.start
          params.end_time = this.current.end
          if(notAllow){
            this.$message({
              showClose: true,
              message: '选中时长不得小于1s',
              type: 'error'
            });
            return
          }
        }else{ // 图片
          params.img_time = 1 //图片时间（后面加上，先写死）
        }
        this.updateStripeList(params)
    },

    // 加入拆条列表 接口
    updateStripeList (params) {
      this.addStripeLoading = true
      this.$http({
        url: "/vw_material/update_open_list/",
        method: "POST",
        data: JSON.stringify(params)
      }).then((res) => {
        this.addStripeLoading = false
        this.$message({
          showClose: true,
          message: '已加入拆条列表',
          type: 'success'
        });
        this.$store.commit("stripeUpdate",{
          type: this.paramsType,
          update: true
        })
      }).catch(error => console.log(error));
    }
  },
  created() {
    // 请求素材详情
    this.getDetail();
    this.getRelatedMaterial()// 获取关联视频、音频
    if (sessionStorage.getItem('time') != null) {
      let time = JSON.parse(sessionStorage.getItem('time'))
      this.clipCurrent.start = time.start
      this.clipCurrent.end = time.end
    }
  },
  filters: {
    // 过滤id,将id前边加上0，总长度为8位
    editId: (val) => {
      let addLen = 8 - String(val).length;
      for (let i = 0; i < addLen; i++) {
        val = "0" + val;
      }
      return val;
    },
    // 过滤时间，将时间的T去掉
    formatTime: (val) => {
      let s = String(val).replace("T", " ");
      return s;
    },

  },
  watch:{
 // 观看列表小窗的视频是否在播放
     "$store.state.playing": {
      deep: true,
      handler: function (newValue, oldValue) {
        if(newValue==1){
          this.player.pause()
        }

      },
    }
  },
  directives: {
    focus: {
      inserted: function(el)  {
        el.querySelector("input").focus();
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/mixin.scss';
.meterial_detail {

  // 公共
  .common_detail{
    padding: 43px 112px 0 65px;
    .top_title {
      .title_btn{
        margin-top: 18px;
        @include flex-align-justify(center,flex-end);

      }

    }
    .material_subcribe{
       font-size: 12px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 13px;
    }
    .material_title{
      font-size: 20px;
      color: rgba(255, 255, 255, 0.8);
      display: inline-block;
    }
    .material_content {
      .existed_tag {
        font-size: 12px;
        background-color: #2a9cff;
        color: #fff;
        border-radius: 2px;
        padding: 5px 9px;
        position: absolute;
        box-sizing: border-box;
      }
      .category_tag {
        min-width: 90px;
        margin-top: -30px;
        margin-left: 9px;
      }
      .video_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: #000000;
        height: 59.5vh;

        &:hover {
          .hover_effect {
            display: flex;
          }
        }
        #audio_play{
          position: absolute;
          bottom: 0;
        }
      }
      .stripe_box{
        display: block;
      }
      .material_bottom{
        .material_info{
          color: rgba(255, 255, 255, 0.65);
          margin-top: 12px;
          .info_mr{
            @media (min-width: 1440px) {
              margin-right: 20px;
            }
            @media (min-width: 1680px) {
              margin-right: 40px;
            }
            @media (min-width: 1920px) {
              margin-right: 69px;
            }
          }
        }
        .stripe_btn {
          padding: 8px 17px;
          border-radius: 6px;
          /deep/span{
            font-size: 12px;
          }
        }
      }
      .hover_effect {
        position: absolute;
        z-index: 10000;
        right: 14px;
        top: 26px;
        display: none;
        .iconfont {
          padding: 4px;
          border-radius: 5px;
          background: rgba(0, 0, 0, 0.5);
          display: inline-block;
          margin-right: 5px;
          cursor: pointer;
        }
        .icon-shoucang {
          color: #ffa200;
        }
      }
    }
  }

  // 视频
  .video_detail {
    /deep/.xgplayer-skin-skinName .xgplayer-progress-dot{
      border-radius: 0;
    }
  }

  // 图片
  .img_detail {

  }

  // 音频
  .audio_detail {
    /deep/.xgplayer-backward,/deep/.xgplayer-forward,/deep/.xgplayer-next,/deep/.xgplayer-prev,/deep/.xgplayer-name{
      display: none;
    }
    /deep/.xgplayer-progress{
      position: absolute;
      top: inherit;
      left: 12px;
      margin-top: -14px;
      .xgplayer-progress-outer{
        height: 3px;
      }
      &:focus,&:hover{
        .xgplayer-progress-outer{
          height: 6px;
          margin-top: 7px;
          .xgplayer-progress-btn{
            top: -3px;
          }
        }
      }
    }
    /deep/.xgplayer-placeholder{
      order: 9;
    }
    /deep/.xgplayer-play{
      width: 40px;
      height: 50px;
      position: relative;
      order: 0;
      // display: block;
      cursor: pointer;
      margin-left: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      .xgplayer-icon{
        padding: 0;
        margin: 0;
        width: 12px;
        height: 14px;
      }
    }
    /deep/.xgplayer-volume{
      order: 10;
      height: 46px;
      .xgplayer-slider{
        left: -6px;
      }
    }
    /deep/.xgplayer-time{
      order: 2;
      line-height: 52px;
      height: 50px;
      text-align: center;
      display: inline-block;
      margin: auto 9px;
      top: inherit;
    }
    /deep/.xgplayer-skin-default .xgplayer-progress-cache{
      background: rgba(42, 156, 255, 0.6);
    }
    /deep/.xgplayer-skin-default .xgplayer-progress-played{
      background-image: linear-gradient(-90deg,#2A9CFF,#2A9CFF);
    }
    /deep/.xgplayer-skin-default .xgplayer-drag{
      background:#2A9CFF;
    }
  }

}

// 标签
.ai_intelligence {
  color: rgba(255, 255, 255, 0.65);
  .row_line {
    display: flex;
    align-items: baseline;
    margin-top:6px;
    .category {
      flex-shrink: 0;
      min-width: 43px;
    }
    .ai_tag {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      /deep/.el-tag {
        background-color: #3e3d4c;
        color: rgba(255, 255, 255, 0.8);
        border: inherit;
        border-radius: 6px;
        position: relative;
        margin: 0 5px;
        margin-bottom: 10px;
        .el-tag__close {
          position: absolute;
          display: none;
          &:hover {
            background-color: inherit;
            color: #2a9cff !important;
          }
        }
        &:hover {
          color: #2a9cff;
          .el-tag__close {
            display: inline-block;
            top: -3px;
            right: -15px;
            color: #9c9ca1;
          }
        }
      }
      .key-tag{
        margin: 0 5px 10px 5px;
      }
    }
    .click_tag{
      /deep/.el-tag {
        cursor: pointer;
      }
    }
    .select_tag{
        color: #2a9cff !important;
    }
  }

}
// 标签

// 关键词 添加
.button-new-tag {
  margin-left: 5px;
  margin-bottom: 10px;
  padding: 0;
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  .icon-jiahao1 {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.6);
    &:hover {
      color: #2a9cff;
    }
  }
}
.input-new-tag {
  width: 116px;
  margin-left: 9px;
  vertical-align: bottom;
  border-radius: 6px;
  margin-bottom: 10px;
  /deep/.el-input__inner {
    border: 1px solid #5e5f72;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.7);
    padding: 0 5px;
    height: 30px;
    line-height: 30px;
  }
}
.operation {
  display: inline-block;
  font-size: 20px;
  margin-bottom: 10px;
  .iconfont {
    cursor: pointer;
    font-size: 20px;
    margin-left: 10px;
  }
  .icon-duihao {
    color: #69ba63;
    &:hover {
      color: #5fed55;
    }
  }
  .icon-cuo {
    color: #894141;
    &:hover {
      color: #d54b4b;
    }
  }
}
// 关键词 添加

// tab切换
.el-tabs{
  height: 100%;
  /deep/.el-tabs__nav-wrap{
    &::after{
      height: 0;
    }
    .el-tabs__nav{
      border: 1px solid #2B2A39;
      width: 99%;
    }
    .el-tabs__active-bar{
      display: none;
    }
    .el-tabs__item{
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
      height: 30px;
      line-height: 30px;
      // width: 50%;
      padding: 0;
      text-align: center;
      &:not(:last-child){
        border-right: 1px solid #2B2A39;
      }
      &.is-active{
        color: rgba(255, 255, 255, 0.9);
        background-color: #2B2A39;
      }
    }
  }
  /deep/.el-tabs__content{
    height: 720px;
    @media (max-height: 768px) {
      height: 470px
    }
    @media (max-height: 900px) {
      height: 600px
    }
    .el-tab-pane{
        border: 1px solid #2D2C39;
        padding: 20px 26px 20px 26px;
        height: 100%;
        box-sizing: border-box;
        @include scrollbar(6px,7px);
    }
  }
}

//关联音乐样式
.related_material{
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid #0F0F1E;
  display: flex;
  padding: 10px 0 10px 0;
  // background-color: red;
  .left_img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 12px;
    position: relative;
    background-color: #000;
    cursor: pointer;
    width: 138px;
    height: 88px;
  }
  .video_time{
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 0.7);
  }
  .right_info{
    width: calc(100% - 150px);
    overflow: hidden;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    position: relative;
    .title{
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      cursor: pointer;
      max-width: 100%;
      display: inline-block;
      &:hover{
        color: #2A9CFF;
      }
    }
    .audio_mt{
      margin-top: 8px;
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      max-width: 100%;
      display: inline-block;
    }
    .related{
      position: absolute;
      bottom: 5px;
      right: 0;
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
    }
  }
}
.related_audio{
  // padding: 20px 0 20px 20px;
 .left_img{
    // width: 66px;
    // height: 66px;
    background-color: #1E1D2D;
    img{
      width: 46px;
      height: 46px;
    }

  }
}
.related_video{
  // padding: 10px 0 10px 10px;
   .left_img{
    width: 138px;
    height: 88px;
  }
  img{
    max-width: 100%;
    max-height: 100%;
    // cursor: pointer;
    flex-shrink: 0;
  }
}

  .ocr_search{
    margin-bottom: 14px;
    /deep/.el-input__inner{
      height: 34px;
      line-height: 34px;
      border-radius: 6px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #1B1B2A;
      border: none;
      color:rgba(255,255,255,0.55);
      }
    /deep/.el-input__inner::placeholder{
      color: rgba(255, 255, 255, 0.55);
    }
    /deep/.el-input-group__append{
      background-color: #1B1B2A;
      border: none;
    }
    /deep/.el-icon-search{
      color:#fff;
      font-weight: bold;
      line-height: 100%;
    }
  }
  .ocr_text{
    color: rgba(255, 255, 255, 0.55);
    &:not(:last-child){
      margin-bottom: 30px;
    }
    .time{
      margin-bottom: 10px;
      cursor: pointer;
      &:hover{
        color: #2A9CFF;
      }
    }
    .text{
      .highlight{
        color: #2A9CFF;
      }
    }
  }
  .ocr_text_select{
    color: #2A9CFF;
  }


.video_tabs{
  #pane-third,#pane-second{
    height: 93%;
    @media (max-height: 768px) {
      height: 88%
    }
    @media (max-height: 900px) {
      height: 90%
    }
    overflow-y: auto;
  }
  #pane-fourth{
    overflow-y: auto;
  }
  /deep/.el-tabs__item{
    width: 25%;
    &:not(:last-child){
      letter-spacing: 10px;
    }
  }
}
.img_tabs{
  /deep/.el-tabs__item{
      width: 50%;
      letter-spacing: 10px;
  }
}
.audio_tabs{
  #pane-first{
    height: 93%;
    @media (max-height: 768px) {
      height: 88%
    }
    @media (max-height: 900px) {
      height: 90%
    }
    overflow-y: auto;
  }
   #pane-second{
    overflow-y: auto;
  }
  // #pane-second{
  //   height: 93%;
  //   @media (max-height: 768px) {
  //     height: 88%
  //   }
  //   @media (max-height: 900px) {
  //     height: 90%
  //   }
  //   overflow-y: auto;
  // }
  // #pane-third{
  //   overflow-y: auto;
  // }
  /deep/.el-tabs__item{
    // width: 33.33%;
    width: 50%;
    &:not(:last-child){
      letter-spacing: 10px;
    }
  }
}

// tab切换
// 空数据
.no_data{
  font-size: 14px;
  font-weight: bold;
  color: #5E5F72;
  text-align: center;
  margin-top: 45px;
}
// 空数据
</style>

<style lang="scss">
.el-dialog.material-dialog {
  background-color: #3e3d4c;
  border-radius: 15px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  padding: 18px 21px 21px;
  .el-dialog__header {
    text-align: center;
    padding: 0;
    padding-bottom: 13px;
    .el-dialog__title {
      color: #fff;
      font-size: 16px;
      letter-spacing: 30px;
    }
    .el-dialog__headerbtn {
      .el-dialog__close {
        color: #fff;
        font-size: 18px;
      }
    }
  }
  .el-dialog__body {
    color: #fff;
    background-color: #2d2c39;
    font-size: 14px;
    padding: 38px 69px 0 69px;
    .radio_check {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
       .disable_radio{
            .el-radio__inner{
              background-color: rgba(255,255,255,0.5);
              border: inherit;
            }
            .el-radio__label{
              color: rgba(255,255,255,0.5);
            }
          }
      .el-radio {
        width: 50%;
        margin-right: 0;
        margin-bottom: 18px;
        color: #fff;
      }
      .el-radio__inner {
        width: 16px;
        height: 16px;
      }
      .is-checked {
        .el-radio__inner {
          width: 16px;
          height: 16px;
          border: 3px solid #fff;
          &::after {
            background-color: inherit;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    padding: 20px 69px 45px 69px;
    background-color: #2d2c39;
    border-radius: 0px 0px 10px 10px;
    .dialog-footer {
      display: flex;
      justify-content: space-between;
      .el-button {
        border-radius: 20px;
        border: 1px solid #5e5f72;
        background-color: inherit;
        color: #fff;
        padding: 6px 15px;
      }
    }
  }
}
.audio_tooltip.el-tooltip__popper{
  max-width: 15%;
}
</style>
