var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasLimits == 0
    ? _c(
        "div",
        { staticClass: "meterial_detail" },
        [
          _c("div", [
            _vm.materialType == "video"
              ? _c(
                  "div",
                  { staticClass: "video_detail common_detail" },
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "material_content",
                        attrs: { type: "flex" }
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-right": "45px" },
                            attrs: { span: 16 }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "video_box stripe_box" },
                              [
                                _c("vue-video-clip", {
                                  ref: "VideoClip",
                                  attrs: {
                                    url: _vm.materialDetailData.url,
                                    current: _vm.clipCurrent,
                                    type: _vm.materialType
                                  },
                                  on: {
                                    getTime: _vm.getTime,
                                    playInit: _vm.playInit
                                  }
                                }),
                                _c("div", { staticClass: "hover_effect" }, [
                                  _c("span", {
                                    staticClass: "iconfont icon-fenxiang",
                                    on: { click: _vm.copyPath }
                                  }),
                                  _c("span", {
                                    staticClass: "iconfont icon-xiazai",
                                    on: { click: _vm.videoDownloadConfirm }
                                  }),
                                  _c("span", {
                                    staticClass:
                                      "iconfont icon-tianjiazhiguankanliebiao",
                                    on: {
                                      click: function($event) {
                                        return _vm.addWatchList()
                                      }
                                    }
                                  }),
                                  (_vm.materialDetailData.collect_if == 0
                                  ? true
                                  : false)
                                    ? _c("span", {
                                        staticClass:
                                          "iconfont icon-weishoucang",
                                        on: { click: _vm.addCollet }
                                      })
                                    : _c("span", {
                                        staticClass: "iconfont icon-shoucang",
                                        on: { click: _vm.addCollet }
                                      }),
                                  _vm.ifcopy
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "existed_tag",
                                          staticStyle: {
                                            top: "-37px",
                                            left: "-28%",
                                            "letter-spacing": "2px"
                                          }
                                        },
                                        [_vm._v(" 已复制链接 ")]
                                      )
                                    : _vm._e()
                                ])
                              ],
                              1
                            ),
                            _c("div"),
                            _c(
                              "div",
                              { staticClass: "material_bottom" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "top_title",
                                    attrs: { type: "flex" }
                                  },
                                  [
                                    _c("el-col", [
                                      _c(
                                        "div",
                                        { staticClass: "title_btn" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "stripe_btn",
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                                loading: _vm.addStripeLoading
                                              },
                                              on: { click: _vm.addStripe }
                                            },
                                            [_vm._v(" 加入拆条列表 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "material_title" }, [
                                  _vm._v(_vm._s(_vm.materialDetailData.name))
                                ]),
                                _c("p", { staticClass: "material_subcribe" }, [
                                  _vm._v(
                                    _vm._s(_vm.materialDetailData.introduce)
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "编号：" +
                                      _vm._s(
                                        _vm._f("editId")(
                                          _vm.materialDetailData.ip_id
                                        )
                                      )
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "商用权限：" +
                                      _vm._s(
                                        _vm.materialDetailData
                                          .commercial_licenses
                                      )
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "分辨率：" +
                                        _vm._s(
                                          _vm.materialDetailData.resolution
                                        )
                                    )
                                  ]),
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "帧率：" +
                                        _vm._s(_vm.materialDetailData.frame)
                                    )
                                  ]),
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "大小：" +
                                        _vm._s(
                                          _vm._f("transformByte")(
                                            _vm.materialDetailData.size
                                          )
                                        )
                                    )
                                  ]),
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "类型：" +
                                        _vm._s(_vm.materialDetailData.format)
                                    )
                                  ]),
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "编码：" +
                                        _vm._s(_vm.materialDetailData.coding)
                                    )
                                  ]),
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "时长：" +
                                        _vm._s(_vm.materialDetailData.duration)
                                    )
                                  ])
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "作者/来源：" +
                                      _vm._s(_vm.materialDetailData.uploader)
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "创建时间：" +
                                      _vm._s(
                                        _vm._f("formatTime")(
                                          _vm.materialDetailData.upload_time
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-tabs",
                              {
                                staticClass: "video_tabs",
                                on: { "tab-click": _vm.handleClick },
                                model: {
                                  value: _vm.activeName,
                                  callback: function($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName"
                                }
                              },
                              [
                                _c(
                                  "el-tab-pane",
                                  { attrs: { label: "标签", name: "first" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "ai_intelligence" },
                                      [
                                        _vm._l(_vm.labelGroupData, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !(
                                                    item.labelData.length == 0
                                                  ),
                                                  expression:
                                                    "!(item.labelData.length==0)"
                                                }
                                              ],
                                              key: index,
                                              staticClass: "row_line"
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "category" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.labelGroupName)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ai_tag click_tag"
                                                },
                                                _vm._l(item.labelData, function(
                                                  tag
                                                ) {
                                                  return _c(
                                                    "el-tag",
                                                    {
                                                      key: tag.tag_id,
                                                      class: tag.select
                                                        ? "select_tag"
                                                        : "",
                                                      attrs: {
                                                        "disable-transitions": false
                                                      },
                                                      on: {
                                                        close: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleClose(
                                                            tag.tag_id,
                                                            1
                                                          )
                                                        },
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.tagClick(
                                                            tag
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(tag.tag_name) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            ]
                                          )
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "row_line",
                                            staticStyle: {
                                              "align-items": "center"
                                            }
                                          },
                                          [
                                            _vm.showKeywords
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "category",
                                                    staticStyle: {
                                                      height: "30px"
                                                    }
                                                  },
                                                  [_vm._v("关键词")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "ai_tag" },
                                              [
                                                _vm._l(
                                                  _vm.keyWordsTags,
                                                  function(tag) {
                                                    return _c(
                                                      "el-tag",
                                                      {
                                                        key: tag.id,
                                                        staticClass: "key-tag",
                                                        attrs: {
                                                          closable: _vm.allowEdit
                                                            ? true
                                                            : false,
                                                          "disable-transitions": false
                                                        },
                                                        on: {
                                                          close: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleClose(
                                                              tag.id,
                                                              2
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              tag.keyword
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm.allowEdit == 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex"
                                                        }
                                                      },
                                                      [
                                                        _vm.keyWordsVisible
                                                          ? _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "focus",
                                                                  rawName:
                                                                    "v-focus"
                                                                }
                                                              ],
                                                              ref:
                                                                "saveTagInput",
                                                              staticClass:
                                                                "input-new-tag",
                                                              attrs: {
                                                                size: "small"
                                                              },
                                                              nativeOn: {
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return _vm.handleInputConfirm()
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.keyWordsValue,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.keyWordsValue = $$v
                                                                },
                                                                expression:
                                                                  "keyWordsValue"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm.keyWordsVisible
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "operation"
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-duihao",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleInputConfirm()
                                                                    }
                                                                  }
                                                                }),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-cuo",
                                                                  on: {
                                                                    click:
                                                                      _vm.clearInput
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          : _c(
                                                              "el-button",
                                                              {
                                                                staticClass:
                                                                  "button-new-tag",
                                                                attrs: {
                                                                  size: "small",
                                                                  type:
                                                                    "primary"
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.addNewLabel
                                                                }
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-jiahao1"
                                                                })
                                                              ]
                                                            ),
                                                        _vm.keyWordsExistedTag
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "existed_tag category_tag"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.tip
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ]
                                        ),
                                        !_vm.showKeywords &&
                                        _vm.labelGroupData.length == 0
                                          ? _c(
                                              "div",
                                              { staticClass: "no_data" },
                                              [
                                                _vm._v(
                                                  " 暂无相关标签和关键词~ "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _vm.activeName == "second"
                                  ? _c(
                                      "div",
                                      { staticClass: "ocr_search" },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              placeholder: "请输入关键字"
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.onAudioSearch($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.AudioSearch,
                                              callback: function($$v) {
                                                _vm.AudioSearch = $$v
                                              },
                                              expression: "AudioSearch"
                                            }
                                          },
                                          [
                                            _c("el-button", {
                                              attrs: {
                                                slot: "append",
                                                icon: "el-icon-search"
                                              },
                                              on: { click: _vm.onAudioSearch },
                                              slot: "append"
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-tab-pane",
                                  {
                                    directives: [
                                      {
                                        name: "infinite-scroll",
                                        rawName: "v-infinite-scroll",
                                        value: _vm.loadMoreAudio,
                                        expression: "loadMoreAudio"
                                      }
                                    ],
                                    staticClass: "second_content",
                                    attrs: {
                                      label: "语音",
                                      name: "second",
                                      "infinite-scroll-distance": 10
                                    }
                                  },
                                  [
                                    _vm._l(_vm.audioList, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "ocr_text",
                                          attrs: {
                                            id:
                                              "audioTime" +
                                              Object.values(item)[1]
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              class: [
                                                "time",
                                                item.highLight
                                                  ? "ocr_text_select"
                                                  : ""
                                              ],
                                              on: {
                                                click: function($event) {
                                                  return _vm.goCurrentTime(item)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(Object.keys(item)[0])
                                              )
                                            ]
                                          ),
                                          _c("p", {
                                            staticClass: "text",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                Object.values(item)[0]
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    }),
                                    _vm.loadingAudio
                                      ? _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              color: "rgba(255,255,255,0.6)"
                                            }
                                          },
                                          [_vm._v("加载中...")]
                                        )
                                      : _vm._e(),
                                    _vm.noMoreAudio && _vm.audioList.length
                                      ? _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              color: "rgba(255,255,255,0.6)"
                                            }
                                          },
                                          [_vm._v("没有更多了~")]
                                        )
                                      : _vm._e(),
                                    !_vm.audioList.length && !_vm.loadingAudio
                                      ? _c("div", { staticClass: "no_data" }, [
                                          _vm._v(" 暂无相关语音~ ")
                                        ])
                                      : _vm._e()
                                  ],
                                  2
                                ),
                                _vm.activeName == "third"
                                  ? _c(
                                      "div",
                                      { staticClass: "ocr_search" },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              placeholder: "请输入关键字"
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.onOcrSearch($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.ocrSearch,
                                              callback: function($$v) {
                                                _vm.ocrSearch = $$v
                                              },
                                              expression: "ocrSearch"
                                            }
                                          },
                                          [
                                            _c("el-button", {
                                              attrs: {
                                                slot: "append",
                                                icon: "el-icon-search"
                                              },
                                              on: { click: _vm.onOcrSearch },
                                              slot: "append"
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-tab-pane",
                                  {
                                    directives: [
                                      {
                                        name: "infinite-scroll",
                                        rawName: "v-infinite-scroll",
                                        value: _vm.loadMoreOcr,
                                        expression: "loadMoreOcr"
                                      }
                                    ],
                                    staticClass: "third_content",
                                    attrs: {
                                      label: "文本",
                                      name: "third",
                                      "infinite-scroll-distance": 10
                                    }
                                  },
                                  [
                                    _vm._l(_vm.ocrList, function(item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "ocr_text",
                                          attrs: {
                                            id:
                                              "ocrTime" + Object.values(item)[1]
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              class: [
                                                "time",
                                                item.highLight
                                                  ? "ocr_text_select"
                                                  : ""
                                              ],
                                              on: {
                                                click: function($event) {
                                                  return _vm.goCurrentTime(item)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(Object.keys(item)[0])
                                              )
                                            ]
                                          ),
                                          _c("p", {
                                            staticClass: "text",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                Object.values(item)[0]
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    }),
                                    _vm.loadingOcr
                                      ? _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              color: "rgba(255,255,255,0.6)"
                                            }
                                          },
                                          [_vm._v("加载中...")]
                                        )
                                      : _vm._e(),
                                    _vm.noMoreOcr && _vm.ocrList.length
                                      ? _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              color: "rgba(255,255,255,0.6)"
                                            }
                                          },
                                          [_vm._v("没有更多了~")]
                                        )
                                      : _vm._e(),
                                    !_vm.ocrList.length && !_vm.loadingOcr
                                      ? _c("div", { staticClass: "no_data" }, [
                                          _vm._v(" 暂无相关文本~ ")
                                        ])
                                      : _vm._e()
                                  ],
                                  2
                                ),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: { label: "关联音乐", name: "fourth" }
                                  },
                                  [
                                    _vm._l(_vm.relatedData, function(item, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass:
                                            "related_material related_audio"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "left_img" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("../assets/images/headset.png"),
                                                  alt: ""
                                                }
                                              }),
                                              _c(
                                                "p",
                                                { staticClass: "video_time" },
                                                [_vm._v(_vm._s(item.duration))]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "right_info" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  directives: [
                                                    {
                                                      name: "delTabIndex",
                                                      rawName: "v-delTabIndex"
                                                    }
                                                  ],
                                                  attrs: {
                                                    effect: "dark",
                                                    content: item.name,
                                                    placement: "bottom-start",
                                                    "popper-class":
                                                      "audio_tooltip"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "one_line_ellipsis title",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.goMaterialDetail(
                                                            item.ip_id,
                                                            "audio"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(_vm._s(item.name))]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "audio_mt" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.photographer)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "related" },
                                                [
                                                  _vm._v(
                                                    "关联视频数量：" +
                                                      _vm._s(
                                                        item.related_video_num
                                                      )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }),
                                    !_vm.relatedData.length
                                      ? _c("div", { staticClass: "no_data" }, [
                                          _vm._v(" 暂时没有相关素材哦~ ")
                                        ])
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.materialType == "img"
              ? _c(
                  "div",
                  { staticClass: "img_detail common_detail" },
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "material_content",
                        attrs: { type: "flex" }
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-right": "45px" },
                            attrs: { span: 16 }
                          },
                          [
                            _c("div", { staticClass: "video_box" }, [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "100%",
                                  "max-height": "100%",
                                  "flex-shrink": "0"
                                },
                                attrs: {
                                  src: _vm.materialDetailData.url,
                                  alt: ""
                                }
                              }),
                              _c("div", { staticClass: "hover_effect" }, [
                                _c("span", {
                                  staticClass: "iconfont icon-fenxiang",
                                  on: { click: _vm.copyPath }
                                }),
                                _c("span", {
                                  staticClass: "iconfont icon-xiazai",
                                  on: { click: _vm.videoDownloadConfirm }
                                }),
                                (_vm.materialDetailData.collect_if == 0
                                ? true
                                : false)
                                  ? _c("span", {
                                      staticClass: "iconfont icon-weishoucang",
                                      on: { click: _vm.addCollet }
                                    })
                                  : _c("span", {
                                      staticClass: "iconfont icon-shoucang",
                                      on: { click: _vm.addCollet }
                                    }),
                                _vm.ifcopy
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "existed_tag",
                                        staticStyle: {
                                          top: "-37px",
                                          left: "-28%",
                                          "letter-spacing": "2px"
                                        }
                                      },
                                      [_vm._v(" 已复制链接 ")]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "material_bottom" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "top_title",
                                    attrs: { type: "flex" }
                                  },
                                  [
                                    _c("el-col", [
                                      _c(
                                        "div",
                                        { staticClass: "title_btn" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "stripe_btn",
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                                loading: _vm.addStripeLoading
                                              },
                                              on: { click: _vm.addStripe }
                                            },
                                            [_vm._v(" 加入拆条列表 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "material_title" }, [
                                  _vm._v(_vm._s(_vm.materialDetailData.name))
                                ]),
                                _c("p", { staticClass: "material_subcribe" }, [
                                  _vm._v(
                                    _vm._s(_vm.materialDetailData.introduce)
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "编号：" +
                                      _vm._s(
                                        _vm._f("editId")(
                                          _vm.materialDetailData.ip_id
                                        )
                                      )
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "商用权限：" +
                                      _vm._s(
                                        _vm.materialDetailData
                                          .commercial_licenses
                                      )
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "分辨率：" +
                                        _vm._s(
                                          _vm.materialDetailData.resolution
                                        )
                                    )
                                  ]),
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "大小：" +
                                        _vm._s(
                                          _vm._f("transformByte")(
                                            _vm.materialDetailData.size
                                          )
                                        )
                                    )
                                  ]),
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "类型：" +
                                        _vm._s(_vm.materialDetailData.format)
                                    )
                                  ])
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "作者/来源：" +
                                      _vm._s(_vm.materialDetailData.uploader)
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "创建时间：" +
                                      _vm._s(
                                        _vm._f("formatTime")(
                                          _vm.materialDetailData.upload_time
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-tabs",
                              {
                                staticClass: "img_tabs",
                                on: { "tab-click": _vm.handleClick },
                                model: {
                                  value: _vm.activeName,
                                  callback: function($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName"
                                }
                              },
                              [
                                _c(
                                  "el-tab-pane",
                                  { attrs: { label: "标签", name: "first" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "ai_intelligence" },
                                      [
                                        _vm._l(_vm.labelGroupData, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !(
                                                    item.labelData.length == 0
                                                  ),
                                                  expression:
                                                    "!(item.labelData.length==0)"
                                                }
                                              ],
                                              key: index,
                                              staticClass: "row_line"
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "category" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.labelGroupName)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ai_tag" },
                                                _vm._l(item.labelData, function(
                                                  tag
                                                ) {
                                                  return _c(
                                                    "el-tag",
                                                    {
                                                      key: tag.tag_id,
                                                      attrs: {
                                                        "disable-transitions": false
                                                      },
                                                      on: {
                                                        close: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleClose(
                                                            tag.tag_id,
                                                            1
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(tag.tag_name) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            ]
                                          )
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "row_line",
                                            staticStyle: {
                                              "align-items": "center"
                                            }
                                          },
                                          [
                                            _vm.showKeywords
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "category",
                                                    staticStyle: {
                                                      height: "30px"
                                                    }
                                                  },
                                                  [_vm._v("关键词")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "ai_tag" },
                                              [
                                                _vm._l(
                                                  _vm.keyWordsTags,
                                                  function(tag) {
                                                    return _c(
                                                      "el-tag",
                                                      {
                                                        key: tag.id,
                                                        staticClass: "key-tag",
                                                        attrs: {
                                                          closable: _vm.allowEdit
                                                            ? true
                                                            : false,
                                                          "disable-transitions": false
                                                        },
                                                        on: {
                                                          close: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleClose(
                                                              tag.id,
                                                              2
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              tag.keyword
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm.allowEdit == 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex"
                                                        }
                                                      },
                                                      [
                                                        _vm.keyWordsVisible
                                                          ? _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "focus",
                                                                  rawName:
                                                                    "v-focus"
                                                                }
                                                              ],
                                                              ref:
                                                                "saveTagInput",
                                                              staticClass:
                                                                "input-new-tag",
                                                              attrs: {
                                                                size: "small"
                                                              },
                                                              nativeOn: {
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return _vm.handleInputConfirm()
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.keyWordsValue,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.keyWordsValue = $$v
                                                                },
                                                                expression:
                                                                  "keyWordsValue"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm.keyWordsVisible
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "operation"
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-duihao",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleInputConfirm()
                                                                    }
                                                                  }
                                                                }),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-cuo",
                                                                  on: {
                                                                    click:
                                                                      _vm.clearInput
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          : _c(
                                                              "el-button",
                                                              {
                                                                staticClass:
                                                                  "button-new-tag",
                                                                attrs: {
                                                                  size: "small",
                                                                  type:
                                                                    "primary"
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.addNewLabel
                                                                }
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "iconfont icon-jiahao1"
                                                                })
                                                              ]
                                                            ),
                                                        _vm.keyWordsExistedTag
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "existed_tag category_tag"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.tip
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ]
                                        ),
                                        !_vm.showKeywords &&
                                        _vm.labelGroupData.length == 0
                                          ? _c(
                                              "div",
                                              { staticClass: "no_data" },
                                              [
                                                _vm._v(
                                                  " 暂无相关标签和关键词~ "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _vm.activeName == "second"
                                  ? _c(
                                      "div",
                                      { staticClass: "ocr_search" },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              placeholder: "请输入关键字"
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.onOcrSearch($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.ocrSearch,
                                              callback: function($$v) {
                                                _vm.ocrSearch = $$v
                                              },
                                              expression: "ocrSearch"
                                            }
                                          },
                                          [
                                            _c("el-button", {
                                              attrs: {
                                                slot: "append",
                                                icon: "el-icon-search"
                                              },
                                              on: { click: _vm.onOcrSearch },
                                              slot: "append"
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-tab-pane",
                                  {
                                    directives: [
                                      {
                                        name: "infinite-scroll",
                                        rawName: "v-infinite-scroll",
                                        value: _vm.loadMoreOcr,
                                        expression: "loadMoreOcr"
                                      }
                                    ],
                                    staticClass: "third_content",
                                    attrs: {
                                      label: "文本",
                                      name: "second",
                                      "infinite-scroll-distance": 10
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "ocr_text" }, [
                                      _c("p", {
                                        staticClass: "time",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.ocrList[0])
                                        }
                                      })
                                    ]),
                                    _vm.loadingOcr
                                      ? _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              color: "rgba(255,255,255,0.6)"
                                            }
                                          },
                                          [_vm._v("加载中...")]
                                        )
                                      : _vm._e(),
                                    _vm.noMoreOcr && _vm.ocrList[0] != ""
                                      ? _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              color: "rgba(255,255,255,0.6)"
                                            }
                                          },
                                          [_vm._v("没有更多了~")]
                                        )
                                      : _vm._e(),
                                    _vm.ocrList[0] == "" && !_vm.loadingOcr
                                      ? _c("div", { staticClass: "no_data" }, [
                                          _vm._v(" 暂无相关文本~ ")
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.materialType == "audio"
              ? _c(
                  "div",
                  { staticClass: "audio_detail common_detail" },
                  [
                    _c(
                      "el-row",
                      {
                        staticClass: "material_content",
                        attrs: { type: "flex" }
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-right": "45px" },
                            attrs: { span: 16 }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "video_box stripe_box" },
                              [
                                _c("div", { staticClass: "hover_effect" }, [
                                  _c("span", {
                                    staticClass: "iconfont icon-fenxiang",
                                    on: { click: _vm.copyPath }
                                  }),
                                  _c("span", {
                                    staticClass: "iconfont icon-xiazai",
                                    on: { click: _vm.videoDownloadConfirm }
                                  }),
                                  (_vm.materialDetailData.collect_if == 0
                                  ? true
                                  : false)
                                    ? _c("span", {
                                        staticClass:
                                          "iconfont icon-weishoucang",
                                        on: { click: _vm.addCollet }
                                      })
                                    : _c("span", {
                                        staticClass: "iconfont icon-shoucang",
                                        on: { click: _vm.addCollet }
                                      }),
                                  _vm.ifcopy
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "existed_tag",
                                          staticStyle: {
                                            top: "-37px",
                                            left: "-28%",
                                            "letter-spacing": "2px"
                                          }
                                        },
                                        [_vm._v(" 已复制链接 ")]
                                      )
                                    : _vm._e()
                                ]),
                                _c("vue-video-clip", {
                                  ref: "VideoClip",
                                  attrs: {
                                    url: _vm.materialDetailData,
                                    current: _vm.clipCurrent,
                                    type: _vm.materialType
                                  },
                                  on: {
                                    getTime: _vm.getTime,
                                    setAudio: _vm.setAudio
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "material_bottom" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "top_title",
                                    attrs: { type: "flex" }
                                  },
                                  [
                                    _c("el-col", [
                                      _c(
                                        "div",
                                        { staticClass: "title_btn" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "stripe_btn",
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                                loading: _vm.addStripeLoading
                                              },
                                              on: { click: _vm.addStripe }
                                            },
                                            [_vm._v(" 加入拆条列表 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "material_title" }, [
                                  _vm._v(_vm._s(_vm.materialDetailData.name))
                                ]),
                                _c("p", { staticClass: "material_subcribe" }, [
                                  _vm._v(
                                    _vm._s(_vm.materialDetailData.introduce)
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "编号：" +
                                      _vm._s(
                                        _vm._f("editId")(
                                          _vm.materialDetailData.ip_id
                                        )
                                      )
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "商用权限：" +
                                      _vm._s(
                                        _vm.materialDetailData
                                          .commercial_licenses
                                      )
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "大小：" +
                                        _vm._s(
                                          _vm._f("transformByte")(
                                            _vm.materialDetailData.size
                                          )
                                        )
                                    )
                                  ]),
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "类型：" +
                                        _vm._s(_vm.materialDetailData.format)
                                    )
                                  ]),
                                  _c("span", { staticClass: "info_mr" }, [
                                    _vm._v(
                                      "时长：" +
                                        _vm._s(_vm.materialDetailData.duration)
                                    )
                                  ])
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "作者/来源：" +
                                      _vm._s(_vm.materialDetailData.uploader)
                                  )
                                ]),
                                _c("p", { staticClass: "material_info" }, [
                                  _vm._v(
                                    "创建时间：" +
                                      _vm._s(
                                        _vm._f("formatTime")(
                                          _vm.materialDetailData.upload_time
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-tabs",
                              {
                                staticClass: "audio_tabs",
                                on: { "tab-click": _vm.handleClick },
                                model: {
                                  value: _vm.activeName,
                                  callback: function($$v) {
                                    _vm.activeName = $$v
                                  },
                                  expression: "activeName"
                                }
                              },
                              [
                                _vm.activeName == "first"
                                  ? _c(
                                      "div",
                                      { staticClass: "ocr_search" },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            attrs: {
                                              placeholder: "请输入关键字"
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.onAudioSearch($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.AudioSearch,
                                              callback: function($$v) {
                                                _vm.AudioSearch = $$v
                                              },
                                              expression: "AudioSearch"
                                            }
                                          },
                                          [
                                            _c("el-button", {
                                              attrs: {
                                                slot: "append",
                                                icon: "el-icon-search"
                                              },
                                              on: { click: _vm.onAudioSearch },
                                              slot: "append"
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-tab-pane",
                                  {
                                    directives: [
                                      {
                                        name: "infinite-scroll",
                                        rawName: "v-infinite-scroll",
                                        value: _vm.loadMoreAudio,
                                        expression: "loadMoreAudio"
                                      }
                                    ],
                                    staticClass: "second_content",
                                    attrs: {
                                      label: "语音",
                                      name: "first",
                                      "infinite-scroll-distance": 10
                                    }
                                  },
                                  [
                                    _vm._l(_vm.audioList, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "ocr_text",
                                          attrs: {
                                            id:
                                              "audioTime1" +
                                              Object.values(item)[1]
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              class: [
                                                "time",
                                                item.highLight
                                                  ? "ocr_text_select"
                                                  : ""
                                              ],
                                              on: {
                                                click: function($event) {
                                                  return _vm.goCurrentTime(item)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(Object.keys(item)[0])
                                              )
                                            ]
                                          ),
                                          _c("p", {
                                            staticClass: "text",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                Object.values(item)[0]
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    }),
                                    _vm.loadingAudio
                                      ? _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              color: "rgba(255,255,255,0.6)"
                                            }
                                          },
                                          [_vm._v("加载中...")]
                                        )
                                      : _vm._e(),
                                    _vm.noMoreAudio && _vm.audioList.length
                                      ? _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              color: "rgba(255,255,255,0.6)"
                                            }
                                          },
                                          [_vm._v("没有更多了~")]
                                        )
                                      : _vm._e(),
                                    !_vm.audioList.length && !_vm.loadingAudio
                                      ? _c("div", { staticClass: "no_data" }, [
                                          _vm._v(" 暂无相关语音~ ")
                                        ])
                                      : _vm._e()
                                  ],
                                  2
                                ),
                                _c(
                                  "el-tab-pane",
                                  {
                                    attrs: { label: "关联视频", name: "third" }
                                  },
                                  [
                                    _vm._l(_vm.relatedData, function(
                                      item,
                                      key,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color:
                                                  "rgba(255, 255, 255, 0.6)",
                                                "font-size": "12px"
                                              }
                                            },
                                            [_vm._v(_vm._s(key))]
                                          ),
                                          _vm._l(item, function(item1, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass:
                                                  "related_material related_video"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "left_img" },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: item1.img_url,
                                                        alt: ""
                                                      },
                                                      on: {
                                                        mouseenter: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          if (
                                                            $event.target !==
                                                            $event.currentTarget
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.imgMouseenter(
                                                            item1
                                                          )
                                                        },
                                                        mouseleave: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          if (
                                                            $event.target !==
                                                            $event.currentTarget
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.imgMouseleave(
                                                            item1
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "video_time"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item1.duration)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "right_info" },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "delTabIndex",
                                                            rawName:
                                                              "v-delTabIndex"
                                                          }
                                                        ],
                                                        attrs: {
                                                          effect: "dark",
                                                          content: item1.name,
                                                          placement:
                                                            "bottom-start",
                                                          "popper-class":
                                                            "audio_tooltip"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "one_line_ellipsis title",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.goMaterialDetail(
                                                                  item1.ip_id,
                                                                  "video"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item1.name)
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "delTabIndex",
                                                            rawName:
                                                              "v-delTabIndex"
                                                          }
                                                        ],
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            item1.introduce,
                                                          placement:
                                                            "bottom-start",
                                                          "popper-class":
                                                            "audio_tooltip"
                                                        }
                                                      },
                                                      [
                                                        item1.introduce
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "audio_mt one_line_ellipsis"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    "简介：" +
                                                                      item1.introduce
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "related"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "关联音频数量：" +
                                                            _vm._s(
                                                              item1.related_audio_num
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    }),
                                    !Object.keys(_vm.relatedData).length
                                      ? _c("div", { staticClass: "no_data" }, [
                                          _vm._v(" 暂时没有相关素材哦~ ")
                                        ])
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "下载",
                visible: _vm.downloadDialog,
                width: "461px",
                top: "30vh",
                "custom-class": "material-dialog",
                "show-close": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.downloadDialog = $event
                }
              }
            },
            [
              _c(
                "span",
                { staticStyle: { display: "block", "margin-bottom": "21px" } },
                [_vm._v("分辨率：")]
              ),
              _c(
                "div",
                { staticClass: "radio_check" },
                [
                  _vm.resolutions.length == 1
                    ? _c(
                        "el-radio",
                        {
                          staticClass: "disable_radio",
                          attrs: { label: "标清" }
                        },
                        [_vm._v("标清")]
                      )
                    : _vm._e(),
                  _vm._l(_vm.resolutions, function(item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: index,
                        attrs: { label: item.url },
                        model: {
                          value: _vm.system,
                          callback: function($$v) {
                            _vm.system = $$v
                          },
                          expression: "system"
                        }
                      },
                      [_vm._v(_vm._s(item.resolution))]
                    )
                  })
                ],
                2
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.downloadConfirm }
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "30px" },
                      on: {
                        click: function($event) {
                          _vm.downloadDialog = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm.hasLimits == 1
    ? _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
            height: "100%"
          }
        },
        [_vm._m(0)]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("i", {
        staticClass: "iconfont icon-meiyouquanxian",
        staticStyle: { "font-size": "100px", color: "rgb(94, 95, 114)" }
      }),
      _c(
        "div",
        {
          staticStyle: {
            "font-weight": "bold",
            "font-family": "Microsoft YaHei",
            "font-size": "20px",
            color: "rgb(94, 95, 114)"
          }
        },
        [_vm._v(" 您暂时没有浏览权限")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }