<template>
  <div class="custom-video">
    <Xgplayer
      id="materialDetail"
      :config="playerConfig"
      @player="playInit($event)"
      class="xgplayer-is-fullscreen"
      v-if="type == 'video'"
    />
    <div v-if="type == 'audio'" id="audioBox">
      <img
        src="../assets/images/music_cover.gif"
        alt=""
        style="max-height: 100%; width: 100%; flex-shrink: 0"
      />
      <div id="audio_play"></div>
    </div>
    <!-- 缩略图，裁剪区域 -->
    <div class="video-controls" @mousedown="handleClick" v-if="ifFullScreen">
      <div class="thumbs" ref="thumbs"></div>
      <div class="control-bars" ref="control-bars">
        <div
          class="control-bars-mask left"
          data-direction="left"
          :style="`width:${leftMovePercentage}%;`"
        ></div>
        <div
          class="control-bars-mask right"
          data-direction="right"
          :style="`width:${rightMovePercentage}%;`"
        ></div>
        <div
          class="control-bars-progress"
          :style="`left:${videoEdit.currentPosition}%;`"
          :class="{ animate: videoEdit.play }"
        >
        </div>
        <div
          class="control-bars-wrapper"
          :style="`left:${leftMovePercentage}%;right:${rightMovePercentage}%;`"
        >
          <div
            ref="move-left-icon"
            class="control-bars-move left"
            @mousedown="handleMoveDown($event, 'left')"
          ></div>
          <div
            ref="move-right-icon"
            class="control-bars-move right"
            @mousedown="handleMoveDown($event, 'right')"
          ></div>
        </div>
      </div>
    </div>
    <!-- 按钮区域 -->
    <div class="video-btn" v-if="ifFullScreen">
      <div class="crop-range">
        <div class="crop-input crop-start">
          <input
            type="text"
            placeholder="00"
            maxlength="2"
            id="range-0"
            disabled
            v-model.number="inputStartLeftTime"
          />
          <span>：</span>
          <input
            type="text"
            placeholder="00"
            maxlength="2"
            id="range-1"
            disabled
            style="text-align:right;"
            v-model.number="inputStartRightTime"
          />
        </div>
        <span style="color: #fff; margin: 0 10px">一</span>
        <div class="crop-input crop-end">
          <input
            type="text"
            placeholder="00"
            maxlength="2"
            id="range-2"
            disabled
            v-model.number="inputEndLeftTime"
          />
          <span>：</span>
          <input
            type="text"
            placeholder="00"
            maxlength="2"
            id="range-3"
            disabled
            style="text-align:right;"
            v-model.number="inputEndRightTime"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Xgplayer from "xgplayer-vue";
import Music from "xgplayer-music";
import { formatDecimal } from "../plugins/solvemethod";
export default {
  name: "vueVideoClip",
  components: {
    Xgplayer,
  },
  props: ["url", "type", "current"],
  data() {
    return {
      videoEdit: {
        start: 0,
        end: 0,
        duration: 0,
        play: false, // 播放状态
        currentPosition: 0, // 当前播放点距离左边的百分比
      },
      duration: 0, // 视频总时长
      currentTime: 0, // 视频当前播放时长 = this.videoDom.currentTime
      leftMovePercentage: 0,
      leftMoveInit: 0,
      rightMovePercentage: 0,
      rightMoveInit: 0,

      player: null,
      playerConfig: {},
      ifFullScreen: true
      // musicPlayer: null,
    };
  },
  mounted() {
    this.init();
    window.onresize = () => {
      this.throttle(this.init(), 300);
    };
  },
  computed: {
    inputStartLeftTime: {
      set(val) {
        val = val * 60 + this.toInt(document.getElementById("range-1").value);
        if (
          val > this.videoEdit.duration ||
          this.videoEdit.duration - val < 1
        ) {
          val = 0;
        }
        this.videoEdit.start = val;
      },
      get() {
        return this.timeTranslate(this.videoEdit.start).split(":")[0];
      },
    },
    inputStartRightTime: {
      set(val) {
        val = this.toInt(document.getElementById("range-0").value) * 60 + val;
        if (
          val > this.videoEdit.duration ||
          this.videoEdit.duration - val < 1
        ) {
          val = 0;
        }
        this.videoEdit.start = val;
      },
      get() {
        return this.timeTranslate(this.videoEdit.start).split(":")[1];
      },
    },
    inputEndLeftTime: {
      set(val) {
        val = val * 60 + this.toInt(document.getElementById("range-3").value);
        if (val > this.videoEdit.duration || val - this.videoEdit.start < 1) {
          val = this.videoEdit.duration;
        }
        this.videoEdit.end = val;
      },
      get() {
        return this.timeTranslate(this.videoEdit.end).split(":")[0];
      },
    },
    inputEndRightTime: {
      set(val) {
        val = this.toInt(document.getElementById("range-2").value) * 60 + val;
        if (val > this.videoEdit.duration || val - this.videoEdit.start < 1) {
          val = this.videoEdit.duration;
        }
        this.videoEdit.end = val;
      },
      get() {
        return this.timeTranslate(this.videoEdit.end).split(":")[1];
      },
    },
  },
  watch: {
    "videoEdit.start": {
      handler(val) {
        this.currentTime = val;
        this.videoEdit.currentPosition =
          (this.currentTime / this.videoEdit.duration) * 100;
        this.leftMovePercentage = this.videoEdit.currentPosition;
        this.$emit("getTime", {
          start: val,
          end: this.videoEdit.end,
        });
      },
      deep: true,
    },
    "videoEdit.end": {
      handler(val) {
        this.currentTime = val;
        this.videoEdit.currentPosition = (this.currentTime / this.videoEdit.duration) * 100;
        this.rightMovePercentage = 100 - this.videoEdit.currentPosition;
        this.$emit("getTime", {
          start: this.videoEdit.start,
          end: val,
        });
      },
      deep: true,
    },
    url(val) {
      this.type == "audio" ? this.setAudio(val.url, val.short_video, val.name) : "";
      if(this.type == "video") {
        this.playerConfig = {
            id: "materialDetail",
            videoInit: true,
            cssFullscreen: true,
            poster: "",
            fluid: true,
            download: false,
            closeVideoDblclick: true,
            // url: "http://test.maixunbytes.com:8097/static/upload/11/360P_1636694775下载 (1).mp4",
            url: val,
            playbackRate: [0.5, 0.75, 1, 1.5, 2],
            ignores: ["fullscreen", "replay"],
            loop: false,
            progressDot: [],
          }
      }
      // if (val) {
      //   console.log("拿到url");
      // }
    },
  },
  methods: {
    // 音频设置
    setAudio(url, short_url, name) {
      let player = new Music({
        id: "audio_play",
        url: [
          {
            src: short_url ? short_url : url,
            name: name,
            vid: "000001",
            poster: "",
          },
        ],
        volume: 0.8,
        width: "100%",
        height: 50,
        preloadNext: true,
        // switchKeepProgress: false,
        volumeShow: true,
        abCycle:{
          loop: false
        }
      });
      this.playInit(player)
    },

    playInit(e) {
      this.player = e;
      this.initMedaData()
      this.player.on("playing", () => {
        // 处理当前位置在末尾的时候先初始化开始播放时间
        if (this.videoEdit.end - this.currentTime < 0.01) {
          this.player.currentTime = this.videoEdit.start;
        }
        // 为了取消当前点平滑移动到开始点的过渡
        setTimeout(() => {
          this.videoEdit.play = true;
        }, 50);
      });
      this.player.on("pause", () => {
        this.videoEdit.play = false;
      });
      this.player.on("ended", () => {
        if(this.type == 'audio') {
          this.$set(this.videoEdit,"currentPosition",this.leftMovePercentage)
          this.$forceUpdate()
          }
      });
      this.player.on("requestCssFullscreen",()=>{
        this.ifFullScreen = false
      })
      this.player.on("exitCssFullscreen",()=>{
        this.ifFullScreen = true
      })
      this.type == "video"? this.$emit("playInit", e):this.$emit("setAudio", e);
    },

    throttle(fn, threshold, scope) {
      let timer;
      return function () {
        const context = scope || this;
        const args = arguments;
        if (!timer) {
          timer = setTimeout(function () {
            fn.apply(context, args);
            timer = null;
          }, threshold);
        }
      };
    },
    init() {
      // 初始化相关元数据
      // this.videoDom = this.$refs['custom-video']
      this.leftIconDom = this.$refs["move-left-icon"];
      this.rightIconDom = this.$refs["move-right-icon"];
      this.thumbsWidth = this.$refs.thumbs.clientWidth;
      this.leftMoveInit = this.getOffset(this.leftIconDom).left + 5;
      this.rightMoveInit = this.getOffset(this.rightIconDom).left + 5;
      // this.minWidthPercentage = ((20 / this.thumbsWidth) * 100).toFixed(4) // 最小裁剪区域所占百分比
      this.minWidthPercentage = 0; // 最小裁剪区域所占百分比
      document.addEventListener("mouseup", (ev) => {
        this.handleMoveStatus = false;
      });
      document.addEventListener("mousemove", (ev) => {
        if (!this.handleMoveStatus) return;
        if (this.handleMoveDirection === "left") {
          const distanceMoveXLeft = ev.clientX - this.leftMoveInit;
          // this.leftMovePercentage = distanceMoveXLeft > 0 ? ((distanceMoveXLeft / (this.thumbsWidth - 20)) * 100).toFixed(4) : 0
          this.leftMovePercentage =  distanceMoveXLeft > 0  ? ((distanceMoveXLeft / this.thumbsWidth) * 100).toFixed(4)  : 0;
          // 控制裁剪百分比最小值
          if (
            this.leftMovePercentage > 100 - this.rightMovePercentage - this.minWidthPercentage
          ) {
            this.leftMovePercentage =  100 - this.rightMovePercentage - this.minWidthPercentage;
          }

          this.videoEdit.start = (  (this.videoEdit.duration * this.leftMovePercentage) / 100).toFixed(4);
        }
        if (this.handleMoveDirection === "right") {
          const distanceMoveXRight = this.rightMoveInit - ev.clientX;
          // this.rightMovePercentage = distanceMoveXRight > 0 ? ((distanceMoveXRight / (this.thumbsWidth - 20)) * 100).toFixed(4) : 0
          this.rightMovePercentage =  distanceMoveXRight > 0  ? ((distanceMoveXRight / this.thumbsWidth) * 100).toFixed(4)  : 0;

          // 控制裁剪百分比最小值
          if (
            this.rightMovePercentage > 100 - this.leftMovePercentage - this.minWidthPercentage
          ) {
            this.rightMovePercentage =  100 - this.leftMovePercentage - this.minWidthPercentage;
          }

          this.videoEdit.end = ( this.videoEdit.duration * (1 - this.rightMovePercentage / 100) ).toFixed(4);
        }
        this.handleClick(ev, this.handleMoveDirection);
      });
    },
    toInt(val) {
      return parseInt(val) || 0;
    },
    // toggleVideoPlay () {
    //   // 处理当前位置在末尾的时候先初始化开始播放时间
    //   if (this.videoEdit.end - this.currentTime < 0.01) {
    //     this.player.currentTime = this.videoEdit.start
    //   }
    //   // 为了取消当前点平滑移动到开始点的过渡
    //   setTimeout(() => {
    //     this.player.play()
    //     this.videoEdit.play = true
    //   }, 50)
    // },
    toggleVideoPause() {
      this.player.pause();
      this.videoEdit.play = false;
    },
    playEnd() {
      this.player.currentTime = this.videoEdit.start;
      this.player.pause();
      this.videoEdit.play = false;
    },
    initMedaData() {
      // 初始化video相关事件
      this.player.on("loadeddata", () => {
        // 获取视频总时长

        this.videoEdit.duration = this.player.duration; // 视频总时长
        if (this.current.start != null && this.current.end != null) {
          this.videoEdit.start = this.current.start
          this.videoEdit.end = this.current.end
        }else {
          this.videoEdit.end = this.videoEdit.duration;
        }
      });
      // const self = this
      // this.player.on('canplay', function () {
      //   // 监听视频可播放时的状态
      // })

      this.player.on("timeupdate", () => {
        // 监听视频播放过程中的时间
        this.videoEdit.currentPosition =
          (this.player.currentTime / this.videoEdit.duration) * 100;
        this.currentTime = this.player.currentTime;
        // this.videoEdit.end - this.currentTime < 0.01 &&this.videoEdit.play
        if (
          this.videoEdit.end - this.currentTime < 0.01 && this.videoEdit.play
        ) {
          this.playEnd();
        }
      });
    },
    handleMoveDown(ev, direction) {
      this.handleMoveStatus = true;
      this.handleMoveDirection = direction;
      this.toggleVideoPause();
      this.handleClick(ev, direction);
    },
    handleClick(ev, direction) {
      // 区分各种情况是为了获取各种情况的当前播放点更准确
      if (direction === "left") {
        this.videoEdit.currentPosition = this.leftMovePercentage;
      } else if (direction === "right") {
        this.videoEdit.currentPosition = 100 - this.rightMovePercentage;
      } else {
        // 点击中间剪辑区域
        this.videoEdit.currentPosition =  ((ev.clientX - this.leftMoveInit) / this.thumbsWidth) * 100;
      }
      this.currentTime = (
        (this.videoEdit.currentPosition * this.videoEdit.duration) / 100 ).toFixed(4);
      this.player.currentTime = this.currentTime;
      // 处理两边 mask 点击而非滑动时,拖拽区域的处理
      if (ev.target.dataset.direction === "left" && !this.handleMoveStatus) {
        this.leftMovePercentage = this.videoEdit.currentPosition;
        this.videoEdit.start = this.currentTime;
      }
      if (ev.target.dataset.direction === "right" && !this.handleMoveStatus) {
        this.rightMovePercentage = 100 - this.videoEdit.currentPosition;
        this.videoEdit.end = this.currentTime;
      }
      // 百分比取最大值：100 最小值：0
      this.videoEdit.currentPosition = this.videoEdit.currentPosition > 100  ? 100  : this.videoEdit.currentPosition;
      this.videoEdit.currentPosition = this.videoEdit.currentPosition < 0 ? 0 : this.videoEdit.currentPosition;
    },
    getOffset(node, offset) {
      // 获取当前屏幕下进度条的左偏移量和又偏移量
      if (!offset) {
        offset = {};
        offset.left = 0;
        offset.top = 0;
      }
      if (node === document.body || node === null) {
        return offset;
      }
      offset.top += node.offsetTop;
      offset.left += node.offsetLeft;
      return this.getOffset(node.offsetParent, offset);
    },
    timeTranslate(t) {
      // 时间转化
      let m = Math.floor(t / 60);
      m < 10 && (m = "0" + m);
      return m + ":" + formatDecimal(((t % 60) / 100),2).slice(-2);

    },
  },
  // 销毁播放器
  destory() {
    if (this.player != null) {
      this.player.destroy(true);
    }
  },
};
</script>
<style scoped lang="scss">
.custom-video {
  user-select: none;
  height: 100%;
  // height: calc(100% - 50px);
  &_container {
    height: 100%;
    overflow: hidden;
  }
  #audio_play {
    position: absolute;
    bottom: 0;
  }
}
.thumbs {
  white-space: nowrap;
  overflow: hidden;
  height: 3px;
  background-color: #409eff;
  background-image: linear-gradient(
    -45deg,
    #a7d1f5 25%,
    transparent 0,
    transparent 50%,
    #a7d1f5 0,
    #a7d1f5 75%,
    transparent 0,
    transparent
  );
  position: relative;
  background-size: 5px 5px;
}
.video-controls {
  position: relative;
  // position: absolute;

  width: calc(100% - 24px);
  margin: 0 12px;
  z-index: 99999;
  // bottom: 54px;
}
.control-bars {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  &-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 10;
    min-width: 20px;
  }
  &-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: rgba(25, 30, 64, 0.8);
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
  &-progress {
    position: absolute;
    height: 16px;
    top: -6px;
    width: 2px;
    background: #04f0fb;
    border-radius: 100px;
    &.animate {
      transition: all 0.3s;
    }
    svg {
      position: absolute;
      top: -10px;
      left: -26px;
    }
    .text {
      position: absolute;
      width: 54px;
      height: 18px;
      top: -10px;
      left: -26px;
      font-size: 12px;
      line-height: 18px;
    }
  }
  &-move {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #04f0fb;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: ew-resize;
    z-index: 2;
    &:active {
      background: #fff;
    }
    &.left {
      // left: -5px;
      left: 0px;
    }
    &.right {
      // right: -5px;
      right: 0px;
    }
  }
}
.video-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  .toggle {
    width: 100px;
    height: 45px;
    line-height: 45px;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;
    transition: background-color 0.2s;
    cursor: pointer;
    &-icon {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 12px solid #fff;
      opacity: 1;
      transition: all 0.2s;
      &.playing {
        width: 12px;
        height: 15px;
        border-left: solid 4px #fff;
        border-right: solid 4px #fff;
        border-top: solid 0 transparent;
        border-bottom: solid 0 transparent;
        box-sizing: border-box;
      }
    }
  }
  .crop {
    &-range {
      display: flex;
      align-items: center;
      font-size: 14px;
      z-index: 99999;
    }
    &-input {
      height: 30px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 15px;
      overflow: hidden;
      padding: 0 19px;
      display: flex;
      align-items: center;
      input {
        float: left;
        width: 24px;
        outline: none;
        background: transparent;
        border: none;
        font-size: 14px;
        color: #fff;
      }
      span {
        width: 8px;
        line-height: 28px;
        height: 28px;
        float: left;
        margin: 0;
        padding: 0;
        color: #fff;
      }
    }
  }
}
.loading {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &-con {
    position: absolute;
    animation: loading 1s linear infinite;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0 2px 0 0 #fff; // ffc7d1
    transform-origin: 50% 50%;
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.xgplayer-skin-default /deep/.xgplayer-progress-dot{
  display: none;
}
</style>
